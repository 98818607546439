import { IonBackButton, IonButton, IonButtons, IonContent, IonGrid, IonHeader, IonIcon, IonLabel, IonPage, IonSegment, IonSegmentButton, IonSlide, IonSlides, IonTitle, IonToolbar } from '@ionic/react';
import { settingsOutline } from 'ionicons/icons';
import React, { useContext, useRef, useState } from 'react';
import Header from '../components/Headers/Header';
import { ModuleProps } from '../components/Modules/Module';
import ModuleList from '../components/Modules/ModuleList';
import Onboarding from '../components/Onboarding/Onboarding';
import SectionTitle from '../components/Titles/SectionTitle';
import { FetchedModuleContext } from '../contexts/ModuleContext';
import './Tab4.css';

import Avatar from "boring-avatars";
import { Heading4, Heading5, MediumParagraph } from '../theme/globalStyles';


const modules: ModuleProps[] = [{
  description: "Ce module explore les différentes émotions",
  name: "Comprendre les émotions ",
  isFavorite: true,
  theme: "emotions",
  longTheme: "Gestion des émotions",
  id: "1289098"
}]

const Tab4: React.FC = () => {


  // a ref variable to handle the current slider
  const slider = useRef<HTMLIonSlidesElement>(null);
  // Segment State
  const [selectedFavSegment, setselectedFavSegment] = useState("0");


  const { modules, loading } = useContext(FetchedModuleContext);
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    loop: false,
    pagination: {
      el: null
    },

  };
  // a function to handle the segment changes
  const handleSegmentChange = (e: any) => {
    setselectedFavSegment(e.detail.value);
    slider.current!.slideTo(e.detail.value);
  };
  // a function to handle the slider changes
  const handleSlideChange = async (event: any) => {
    let index: number = 0;
    await event.target.getActiveIndex().then((value: any) => (index = value));
    setselectedFavSegment('' + index)
  }

  return (
    <IonPage>
      {/* <Header name="Mon espace" icon={settingsOutline} iconTarget="/settings" /> */}
      <IonHeader className="ion-padding-horizontal ion-no-border">
        <IonToolbar   >
          <IonButtons slot="start">

            <IonBackButton color="dark" defaultHref="/" text="" />
          </IonButtons>
          <IonButtons slot="primary" collapse={true}>
            <IonButton fill="clear" color="dark" routerLink="/settings">
              <IonIcon icon={settingsOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle  >
            <Heading4 style={{ color: "var(--ion-color-primary)", textAlign: "center" }}>Mon espace</Heading4>
          </IonTitle>
        </IonToolbar>

      </IonHeader>
      <IonContent fullscreen>
        {/* <Header name="Mon espace" icon={settingsOutline} collapsible={true} iconTarget="/settings" /> */}
        <div className="page-wrapper ion-padding-horizontal">

          <div className="page-wrapper-content ">
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "1em" }}>
              <div style={{ borderRadius: "50%" }}>

                <Avatar
                  size={120}
                  name="Emmet Larribeau"
                  variant="beam"
                  colors={["#366D9C", "#E05926", "#2FA377", "#EFA948", "#EF4949"]}
                />
              </div>
              <Heading5>Emmet Larribeau</Heading5>
              <MediumParagraph style={{ color: "var(--ion-color-medium)" }} >emmett.larribeau@example.com</MediumParagraph>
            </div>
            <SectionTitle title="Mes favoris" />
            <IonSegment value={selectedFavSegment} onIonChange={e => handleSegmentChange(e)}>
              <IonSegmentButton value="0">
                <IonLabel>Modules</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="1">
                <IonLabel>Outils</IonLabel>
              </IonSegmentButton>
            </IonSegment>
            <IonSlides pager={true} options={slideOpts} onIonSlideDidChange={(e) => handleSlideChange(e)} ref={slider}>
              <IonSlide className="ion-padding">
                Vous n'avez aucun module favori pour l'instant
              </IonSlide>
              <IonSlide className="ion-padding">
                Vous n'avez aucun outil favori pour l'instant
              </IonSlide>
            </IonSlides>
          </div>
        </div>

      </IonContent>
    </IonPage>
  );
};

export default Tab4;