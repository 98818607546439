import { IonSlide, IonSlides } from '@ionic/react';
import React from 'react';
import Module, { ModuleProps } from "./Module";

interface ModuleListProps {
    modules: ModuleProps[];
    filteredTheme?: "emotions" | "confiance" | "affirmation" | "problemes" | "stress" | "convictions" | "pensée" | "valeurs" | "objectifs" | "contrôle" | "motivation" | "estime" | "obstacles" | "besoins";
    cps?: string[];
    surveyCompleted?: boolean;
}

const sliderOptions = {
    // initialSlide: 0,
    speed: 400,
    slidesPerView: 1.1,
    spaceBetween: 8,
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 1.15,
            spaceBetween: 20
        },
        // when window width is >= 480px
        480: {
            slidesPerView: 2.2,
            spaceBetween: 26
        },
        // when window width is >= 640px
        640: {
            slidesPerView: 2.2,
            spaceBetween: 16
        }
    }
}

const ModuleSlider: React.FC<ModuleListProps> = ({ modules, filteredTheme, cps, surveyCompleted }) => {
    var filteredModules = modules;
    // console.log('test',surveyCompleted)
    if (surveyCompleted){

      filteredModules = cps ? modules.filter(module => cps.includes(module.theme)) : modules;
    //   console.log(filteredModules)

    }
    return (
        <IonSlides options={sliderOptions} style={{padding: "0.5em 0"}}>
            {filteredModules.map((module, index) => (
                <IonSlide className="" key={index} style={{ height: "auto" }}>
                    <Module
                        name={module.name}
                        isFavorite={module.isFavorite}
                        description={module.description}
                        id={module.id}
                        theme={module.theme}
                        thumbnail={module.thumbnail}
                        readingTime={module.readingTime}


                    />
                </IonSlide>
            ))}
        </IonSlides>
    )
}

export default ModuleSlider;
