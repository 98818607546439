
const ModalHandle = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ height: "5px", width: "80px", backgroundColor: "var(--ion-color-medium)", margin: "1rem 0", borderRadius: "5px" }}></div>
        </div>
    )
}

export default ModalHandle
