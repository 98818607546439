import React, {createContext, useEffect, useState} from "react"


export interface FavoritesContextProps {
  favoritesModules: [],
  setFavoritesModules: (favoritesModules: []) => void,
  favoritesTools: [string],
  setFavoritesTools: (favoritesModules: [string]) => void,
}

export const FavoritesContext = createContext( {} as FavoritesContextProps);

const FavoritesContextProvider = (props: { children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; })=>{

  const [favoritesModules, setFavoritesModules] = useState<any>([])
  const [favoritesTools, setFavoritesTools] = useState<any>([])

  const providerValue: FavoritesContextProps = {favoritesModules, setFavoritesModules, favoritesTools, setFavoritesTools}
  return (
      <FavoritesContext.Provider value={providerValue}>
          {props.children}
      </FavoritesContext.Provider>
  )
}

export default FavoritesContextProvider;
