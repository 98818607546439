import { IonPage, IonContent, IonToolbar, IonBackButton, IonHeader, IonTitle, IonIcon, IonButton, IonButtons, IonCard, IonSlide, IonSlides, IonItem, IonInput, IonItemDivider, IonLabel, IonTextarea,IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonFabButton, IonGrid, IonRow, IonText, IonRadio, IonList, IonRadioGroup, IonAlert, IonCheckbox, IonItemSliding,IonItemOptions,IonItemOption, IonSegment, IonSegmentButton} from '@ionic/react'
// import { ModuleProps } from './Module';
import tempImage from "../Themes/Emotion.png";
import { ColumnContainer, Heading5, MediumParagraph  } from '../../theme/globalStyles';
import React,{ useRef, useState,useContext, useEffect } from 'react'
import { help, add, close,trash, heart, heartOutline } from 'ionicons/icons'
import { getColor } from '../Themes/Theme';

import { FavoritesContext } from '../../contexts/FavoritesContext'

import { toast } from '../Toasts/Toast';


export interface FavoriteProps {
    name: string;

}

  const Favorite: React.FC<FavoriteProps> = ({ name }) => {

    const { favoritesModules, setFavoritesModules, favoritesTools, setFavoritesTools } = useContext<any>(FavoritesContext);

    const handleFavoris = (e:any) => {
      if(favoritesTools.includes(e)){
        setFavoritesTools(favoritesTools.filter((el:any) => el!=e))
      }
      else{
        favoritesTools.push(e)
        setFavoritesTools([...favoritesTools])
        toast("L'exercice à bien été enregistré dans vos favoris")
      }
    }

    return (
      <IonButton onClick={() => handleFavoris(name)} fill="clear" color="dark" >
      {favoritesTools.includes(name) && <IonIcon icon={heart} />}
      {!favoritesTools.includes(name) && <IonIcon icon={heartOutline} />}
      </IonButton>
    )
}

export default Favorite
