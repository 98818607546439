import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import { HorizontalScroll, MediumParagraph } from "../../theme/globalStyles";
import Module, { ModuleProps } from "./Module";

interface ModuleListProps {
  modules: ModuleProps[];
  filteredTheme?: "emotions" | "confiance" | "affirmation" | "problemes" | "stress" | "convictions" | "pensée" | "valeurs" | "objectifs" | "contrôle" | "motivation" | "estime" | "obstacles" | "besoins";
}


const ModuleList: React.FC<ModuleListProps> = ({ modules, filteredTheme }) => {
  const filteredModules = filteredTheme ? modules.filter(module => module.theme === filteredTheme) : modules;

  return (
    // <HorizontalScroll>
    <>
    {filteredModules.length > 0 ?  (<IonGrid className="ion-margin-vertical">
      <div className="ion-padding-vertical" style={{display: "grid", gap: "1em", gridTemplateColumns: "repeat(auto-fill, minmax(15em, 1fr))"}}>
        { filteredModules.map((module) => (

          <Module
          name={module.name}
          isFavorite={module.isFavorite}
          description={module.description}
          theme={module.theme}
          key={module.id}
          id={module.id}
          readingTime={module.readingTime}
          />

          )) }
          </div>
      {/* </IonRow> */}
    </IonGrid>): (<MediumParagraph>Aucun module disponible pour la compétence sélectionné</MediumParagraph>)}
    </>
    // </HorizontalScroll>
  );
};

export default ModuleList;
