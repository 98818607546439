import { IonPage, IonContent, IonToolbar, IonBackButton, IonHeader, IonTitle, IonIcon, IonButton, IonButtons, IonCard, IonSlide, IonSlides, IonItem, IonInput, IonItemDivider, IonLabel, IonTextarea,IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonFabButton, IonGrid, IonRow, IonText, IonRadio, IonList, IonRadioGroup, IonAlert, IonSegment, IonSegmentButton} from '@ionic/react'
import { happyOutline, help, manOutline, heart, heartCircleOutline, helpCircleOutline, closeCircleOutline } from 'ionicons/icons'
import React, { useRef, useState,useContext, useEffect } from 'react'
import { toast } from '../../components/Toasts/Toast';
import Favorite from "../../components/Outils/Favorite"
import { Heading4, Heading5, LargeParagraph, MediumButton } from '../../theme/globalStyles';
import { QualitiesContext } from '../../contexts/QualitiesContext';

import { Gesture, GestureConfig, createGesture } from '@ionic/core';
import "./Qualities.css";

const Qualities = () => {

  const {mySkills, setMySkills, qualities,setQualities}:any = useContext(QualitiesContext)
  const qu = [['Fiable','synFiable'],['Curieux','synCurieux'],['Ponctuel','synPonctuel']];
  const [category, setCategory] = useState('');
  const [index, setIndex] = useState(Object.keys(qualities).length-1)
  const [selectedSegment, setSelectedSegment] = useState('')

    const content = document.querySelectorAll<HTMLElement>('.swipeCards')
      for (let i = 0; i < content.length; i++){
        const element = content[i]
        const options: GestureConfig = {
          el: element,
          gestureName: 'tinder-swipe',
          onStart: () => {
              element.style.transition = '.2s ease-out'
            },
          onMove: (detail) => {
              element.style.transform = 'translate('+detail.deltaX+'px, '+detail.deltaY+'px) rotate('+detail.deltaX / 10+'deg)';
            },
          onEnd: (detail) => {
            if (detail.deltaX > 150) {
                      if(category == ''){
                        toast('Merci de selectionner un contexte')
                        element.style.transform = '';
                      }
                      else{
                      element.style.transform = 'translateX(200vw) rotate('+detail.deltaX / 10+'deg)';
                      setIndex(index-1)
                          if(mySkills[category]){
                                mySkills[category].push(element.children[0].childNodes[1].textContent)
                                setMySkills({...mySkills})
                              }
                          else{
                                mySkills[category] = [element.children[0].childNodes[1].textContent]
                                setMySkills({...mySkills})
                              }
                              setCategory('')
                              setSelectedSegment(Object.keys(mySkills)[0])
                    }

                 }

            else if (detail.deltaX < -150) {
                    element.style.transform = 'translateX(-200vw) rotate('+detail.deltaX / 10+'deg)';
                    setIndex(index-1)
                    setCategory('')
                 }

            else {
                  element.style.transform = '';
                }
            }
        };

        const gesture: Gesture = createGesture(options);

        gesture.enable(true);


      }// end for

   const handleCategory = (e:any) => {
     setCategory(e.detail.value)
   }

   const handleSelected = (e:any) => {
     const el = document.querySelectorAll<HTMLElement>('.swipeCards')[e]
     if(category == ''){
       toast('Merci de selectionner un contexte')
       el.style.transform = '';
     } else {
           el.style.transition = '1.2s ease-out'
           el.style.transform = 'translateX(200vw) rotate('+220 / 10+'deg)';
           setIndex(index-1)
           if(mySkills[category]){
                 mySkills[category].push(el.children[0].childNodes[1].textContent)
               }
           else{
                 mySkills[category] = [el.children[0].childNodes[1].textContent]
               }
               setCategory('')
               setMySkills(mySkills)
               setSelectedSegment(Object.keys(mySkills)[0])
            }
    }

   const handleUnselected = (e:any) => {
     const el = document.querySelectorAll<HTMLElement>('.swipeCards')[e]
     el.style.transition = '1.2s ease-out'
     el.style.transform = 'translateX(-200vw) rotate('+ -220 / 10+'deg)';
     setIndex(index-1)
     setCategory('')
   }

   const handleClick = (e :any) => {
     const element = document.querySelectorAll<HTMLElement>('.swipeCards')[e]
     element.style.transition = '0.3s '
     element.classList.add('flip')
   }
   const handleClose = (e :any) => {
     const element = document.querySelectorAll<HTMLElement>('.swipeCards')[e]
     element.style.transition = '0.3s '
     element.classList.remove('flip')
   }

   const end = () => {
     return(
       <div style={{display:'flex', flexWrap:'wrap'}}>
       {mySkills[selectedSegment].map((quality:any) =>{
         return(

           <p key={quality} style={{padding:'10px'}}>{quality}</p>

         )
       })}
       </div>
     )
   }


  return(
    <IonPage>
      <IonContent fullscreen style={{ "--background": "var(--ion-color-light)" }}>
          <IonHeader style={{ "--background": "transparent" }}>
              <IonToolbar>
                  <IonButtons slot="start">
                      <IonBackButton color="dark" defaultHref="/" text="" />

                  </IonButtons>
                  <IonTitle>
                      <Heading5>Mes qualités</Heading5>
                  </IonTitle>
                  <IonButtons slot="end">
                    <Favorite name='/qualities'/>
                  </IonButtons>
              </IonToolbar>

          </IonHeader>
          <div className="page-wrapper ion-padding-horizontal" style={{ backgroundColor: "var(--ion-color-light)" }}>
              <div className="page-wrapper-content " >

              <LargeParagraph style={{margin:'10px 0 10px 0'}}>
                  Nous vous proposons ici une suite d'adjectifs pour lesquelles vous devrez indiquer si elle vous correspond ou non. Si la qualité vous correspond, il faudra préciser dans quel contexte elle est le plus adaptée.
              </LargeParagraph>

              <div className="quCard" style={{height: '430px'}}>

                {index<0 &&
                  <IonSegment onIonChange={(e:any)=>setSelectedSegment(e.detail.value)} value={selectedSegment}>
                  {Object.keys(mySkills).map( (key) => {
                    return(
                        <IonSegmentButton  value={key} >
                          <IonLabel>{key}</IonLabel>
                        </IonSegmentButton>
                    )
                  })}
                  </IonSegment>

                }

                {index<0 &&
                end()}

                {qualities.map((quality:any,i:any) => {
                  return(

                    <IonCard key={qualities.indexOf(quality)} className="swipeCards">

                        <div className='frontCard' key={"front"+qualities.indexOf(quality)}>
                        <IonIcon onClick={() => handleClick(index)} className = 'helpQuality' color="primary" icon={helpCircleOutline}/>
                        <IonCardHeader>
                          <Heading4 className = "cardTitle" >{quality[0]}
                          </Heading4>
                        </IonCardHeader>

                        <IonCardContent>
                          <IonRadioGroup onIonChange={(e) => handleCategory(e)}>
                            <IonList lines="none">
                              <div className="ion-padding-horizontal" style={{ display: 'flex', flexDirection: 'column', gap: "8px" }}>
                                <IonItem style={{ width: '100%', padding:'2px',border: '0.1em solid var(--ion-color-medium)', borderRadius:'8px', color: 'var(--ion-color-medium)'}}>
                                    <MediumButton style={{marginRight:'10px'}}>Professionnel</MediumButton>
                                    <IonRadio slot="end" value="Professionnel" style={{marginLeft: '32px'}}/>
                                </IonItem>

                                <IonItem style={{ width: '100%', padding:'2px',border: '0.1em solid var(--ion-color-medium)', borderRadius:'8px', color: 'var(--ion-color-medium)'}}>
                                    <MediumButton>Familiale</MediumButton>
                                    <IonRadio slot="end" value="Familiale" />
                                </IonItem>

                                <IonItem style={{ width: '100%',padding:'2px',border: '0.1em solid var(--ion-color-medium)', borderRadius:'8px', color: 'var(--ion-color-medium)'}}>
                                    <MediumButton>Entre Amis</MediumButton>
                                    <IonRadio slot="end" value="Entre Amis" />
                                </IonItem>
                              </div>
                            </IonList>
                          </IonRadioGroup>
                          </IonCardContent>
                        </div>
                        <div className='backCard' key={"back"+qualities.indexOf(quality)}>
                        <IonIcon onClick={() => handleClose(index)} className = 'helpQuality' color="primary" icon={closeCircleOutline}/>
                          <IonCardContent style={{width: "100%"}}>
                            {quality[1]}
                          </IonCardContent>
                        </div>
                    </IonCard>

                )})}
              </div>

                {index >=0 &&
                  <div className='button_box'>
                <IonButton onClick={() => handleUnselected(index)} className='large_button' mode="ios" shape="round" fill="outline" color="primary">
                    <IonIcon color="danger" icon="close"/>
                </IonButton>
                <div className="space"></div>
                <IonButton onClick={() => handleSelected(index)} className='large_button' mode="ios" shape="round" fill="outline" color="primary" >
                    <IonIcon color="success" icon={heart}/>
                </IonButton>
                </div>}



              </div>

            </div>
      </IonContent>
    </IonPage>
  )
}

export default Qualities
