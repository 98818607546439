import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel } from '@ionic/react'
import { home, compass, trendingUp, personCircle,hammer } from 'ionicons/icons'
import React, {useContext} from 'react'
import { Route, Redirect } from 'react-router'
import FetchedModuleContextProvider from '../../contexts/ModuleContext'
import Tab1 from '../../pages/Tab1'
import Tab2 from '../../pages/Tab2'
import Tab3 from '../../pages/Tab3'
import Tab4 from '../../pages/Tab4'
import TabOutils from '../../pages/TabOutils'

import "./Tabs.css"
import {CalendarContext} from '../../contexts/CalendarContext';
import { checkmark } from 'ionicons/icons'


function Tabs() {

  const {day,setDay} = useContext(CalendarContext);
  const week: any = {"0":["unchecked","light"],"1":["unchecked","light"],"2":["unchecked","light"],"3":["unchecked","light"],"4":["unchecked","light"],"5":["unchecked","light"],"6":["unchecked","light"]}
  function handleChange(){
    const today = new Date(),
    date = today.getDate();

    week[today.getDay()][0] = checkmark

    for (var i=1; i < today.getDay()+1; i++){
      if(i==7){
        week[0][1] = "primary"
      }
      else{
        week[i][1] = "primary"
      }
    }
    setDay(week)
  }

  return (
    <IonTabs >
      <IonRouterOutlet>
        {/* <FetchedModuleContextProvider> */}
        {/* <Route exact path="/module/:id" >
            <ViewModule />
          </Route> */}
        <Route exact path="/tabs/accueil">
          <Tab1 />
        </Route>
        <Route exact path="/tabs/parcourir">
          <Tab2 />
        </Route>
        <Route exact path="/tabs/outils">
          <TabOutils />
        </Route>
        <Route path="/tabs/suivi">
          <Tab3 />
        </Route>

        <Route exact path="/tabs">
          <Redirect to="/tabs/accueil" />
        </Route>
        {/* </FetchedModuleContextProvider> */}
      </IonRouterOutlet>

      <IonTabBar className="bottom-tab-bar" style={{  backgroundColor: "white", "--background": "white", "--color-selected": "var(--ion-color-primary-shade)", "--color": "var(--ion-color-medium-shade)", height: "70px", boxShadow:'0px -1px 4px  rgba(0, 0, 0, 0.3)' }} slot="bottom" onClick={handleChange}>
        <IonTabButton  tab="tab1" href="/tabs/accueil">
          <IonIcon icon={home} color='dark' />
          <IonLabel style={{color:'black'}}>Accueil</IonLabel>
        </IonTabButton>
        <IonTabButton tab="tab2" href="/tabs/parcourir">
          <IonIcon icon={compass} color='dark'/>
          <IonLabel style={{color:'black'}}>Apprendre</IonLabel>
        </IonTabButton>
        <IonTabButton tab="tabOutils" href="/tabs/outils">
          <IonIcon icon={hammer} color='dark'/>
          <IonLabel style={{color:'black'}}>S'exercer</IonLabel>
        </IonTabButton>
        <IonTabButton tab="tab3" href="/tabs/suivi">
          <IonIcon icon={personCircle} color='dark'/>
          <IonLabel style={{color:'black'}}>Mon espace</IonLabel>
        </IonTabButton>
        {/* <IonTabButton tab="tab4" href="/tabs/moi">
          <IonIcon icon={personCircle} />
          <IonLabel>Mon espace</IonLabel>
        </IonTabButton> */}
      </IonTabBar>
    </IonTabs>
  )
}

export default Tabs
