import { IonSlide, IonSlides } from '@ionic/react'
import React from 'react'
import SkeletonCard from './SkelettonCard'



const sliderOptions = {
    // initialSlide: 0,
    speed: 400,
    slidesPerView: 1.1,
    spaceBetween: 8,
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 1.3,
            spaceBetween: 10
        },
        // when window width is >= 480px
        480: {
            slidesPerView: 2.2,
            spaceBetween: 16
        },
        // when window width is >= 640px
        640: {
            slidesPerView: 2.2,
            spaceBetween: 16
        }
    }
}

function SkeletonSlider() {
    
    return (

<IonSlides options={sliderOptions}>


        <IonSlide >
            <SkeletonCard/>
        </IonSlide>
        <IonSlide>
            <SkeletonCard/>
        </IonSlide>
        <IonSlide>
            <SkeletonCard/>
        </IonSlide>
</IonSlides>
    )
}

export default SkeletonSlider
