import React, { useRef, useState } from 'react'
import { IonButton, IonButtons, IonContent, IonHeader, IonModal, IonPage, IonSlide, IonSlides, IonToolbar } from '@ionic/react'
import { Heading4, Heading5, LargeParagraph, LargeButton, MediumParagraph, RowContainer } from '../../theme/globalStyles';
import Button from '../Buttons/Button';
import "./Onboarding.css";
import Lottie from "react-lottie";
import outilAnimation from "./lottieFiles/outils.json"
import persoAnimation from "./lottieFiles/perso.json"
import responsiveAnimation from "./lottieFiles/responsive.json"


const outilsOptions = { loop: true, animationData: outilAnimation, autoplay: true }
const persoOptions = { loop: true, animationData: persoAnimation, autoplay: true }
const responsiveOptions = { loop: true, animationData: responsiveAnimation, autoplay: true }

const Onboarding: React.FC = () => {

    const [currentIndex, currentIndexSet] = useState(0)
    const onboardingSlides = useRef<HTMLIonSlidesElement>(null);


    const handleSwipeNext = async () => {
        const swiper = await onboardingSlides.current!.getSwiper()
        swiper.slideNext()
        // console.log("Link to next page")
    }

    const handleSwipeFinal = async () => {
        const swiper = await onboardingSlides.current!.getSwiper()
        swiper.slideTo(2)
        // console.log("Link to next page")
    }
    // a function to handle the slider changes
    const handleSlideChange = async (event: any) => {
        let index: number = 0;
        await event.target.getActiveIndex().then((value: any) => {
            index = value
            currentIndexSet(value)
            // console.log(index)
        })
        // setCurrentQuestion(index)
    }
    const slideOpts = {
        initialSlide: 0,
        slidesPerView: 1,
        speed: 600,
    };
    const [tutorialModal, setTutorialModal] = useState(true);
    return (
        // <IonModal
        //     isOpen={tutorialModal}
        //     cssClass="onboarding-modal"
        //     onDidDismiss={() => setTutorialModal(false)}
        //     swipeToClose={true}
        //     mode="ios"

        // >
            <IonPage>
                {/* <IonHeader>
                <IonToolbar >
                    <IonButtons slot="end">
                        {currentIndex != 2 && <IonButton color="dark" onClick={() => handleSwipeFinal()} >
                            <MediumParagraph>
                                Ignorer
                            </MediumParagraph>
                        </IonButton>}
                    </IonButtons>
                </IonToolbar>
                </IonHeader> */}
                <IonContent fullscreen>
                    <IonSlides ref={onboardingSlides} style={{ height: "100%", width: "100%", "--bullet-background": "var(--ion-color-dark)" }} mode="ios" pager={true} options={slideOpts} onIonSlideDidChange={(e) => handleSlideChange(e)}>
                        <IonSlide  style={{ display: "flex", flexDirection: "column" }} >

                            <div className="ion-padding-horizontal">

                                <Heading4 style={{ color: "var(--ion-color-primary)" }}>
                                    {/* Utilisation Multiplateforme */}
                                    Disponible sur tous vos appareils !
                                </Heading4>
                                <Lottie isClickToPauseDisabled={true} options={responsiveOptions} height={230} width={300} />
                                <LargeParagraph style={{ color: "var(--ion-color-dark)", minHeight: "5em" }}>
                                    Connectez vous et bénéficiez de la même expérience sur tous vos appareils

                                </LargeParagraph>
                            </div>
                            <RowContainer>

                                {/* <IonButton fill="clear" color="primary" onClick={() => handleSwipeFinal()} >
                        <MediumParagraph>
                        Ignorer
                        </MediumParagraph>
                    </IonButton> */}
                                <IonButton onClick={() => handleSwipeNext()} style={{ "--border-radius": "16px", "--padding-bottom": "16px", "--padding-top": "16px" }} className="ion-margin-top" size="large" expand="block" fill="solid" color="primary">
                                    <LargeButton>

                                        Suivant
                                    </LargeButton>

                                </IonButton>
                            </RowContainer>
                        </IonSlide>
                        <IonSlide className="ion-padding" style={{ display: "flex", flexDirection: "column" }}>
                            <div className="ion-padding-horizontal">

                                <Heading4 style={{ color: "var(--ion-color-primary)" }}>
                                    Expérience personnalisée
                                </Heading4>
                                <Lottie isClickToPauseDisabled={true} options={persoOptions} height={230} width={300} />
                                <LargeParagraph style={{ color: "var(--ion-color-medium)", minHeight: "5em" }}>

                                    Nous vous recommandons des modules toujours adpatés à vos besoins
                                </LargeParagraph>
                            </div>

                            <IonButton onClick={() => handleSwipeNext()} style={{ "--border-radius": "16px", "--padding-bottom": "16px", "--padding-top": "16px" }} className="ion-margin-top" size="large" expand="block" fill="solid" color="primary">
                                <LargeButton>

                                    Suivant
                                </LargeButton>

                            </IonButton>

                            {/* <IonButton onClick={()=> handleSwipeNext()} size="large" expand="block" color="primary">Continuer</IonButton> */}
                        </IonSlide>
                        <IonSlide style={{ display: "flex", flexDirection: "column"}}>
                            <div className="ion-padding-horizontal">

                                <Heading4 style={{ color: "var(--ion-color-primary)" }}>
                                    Apprentissage intéractif
                                </Heading4>
                                <Lottie isClickToPauseDisabled={true} options={outilsOptions} height={230} width={300} />
                                <LargeParagraph style={{ color: "var(--ion-color-dark)", minHeight: "5em" }}>
                                    Complétez votre découverte des compétences de vie en utilisant nos outils pratiques

                                </LargeParagraph>
                            </div>
                            <IonButton routerLink="/introduction" style={{ "--border-radius": "16px", "--padding-bottom": "16px", "--padding-top": "16px" }} className="ion-margin-top" size="large" expand="block" fill="solid" color="primary">
                                <LargeButton>

                                    Commencer
                                </LargeButton>

                            </IonButton>

                        </IonSlide>

                    </IonSlides>
                </IonContent>
            </IonPage>

        // </IonModal>
    )
}

export default Onboarding
