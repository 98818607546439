import { IonCard, IonCardTitle, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonProgressBar, IonRow, IonThumbnail } from '@ionic/react';
import React from 'react';
// import { ModuleProps } from './Module';
import tempImage from "../Themes/Emotion.png";
import { ColumnContainer, Heading5, MediumParagraph  } from '../../theme/globalStyles';
import { happyOutline, helpOutline, listOutline, accessibilityOutline } from 'ionicons/icons';
import { getColor } from '../Themes/Theme';


export interface OutilProps {
    name: string;
    theme: string;
    longTheme?: string;
    id: string;
    isFavorite?: boolean;
    thumbnail?: string;
    description?: string;
    body?: any;
    unique?: boolean;
    icon?:any;
    setHandler: React.Dispatch<React.SetStateAction<boolean>>
}

const Outil: React.FC<OutilProps> = ({ name,
    isFavorite,
    thumbnail,
    description,
    theme,
    icon,
    setHandler }) => {

    let color = getColor(theme)
    // console.log("outils", theme)
    return (
        <IonCard onClick={() => setHandler(true)} mode="ios" button={true} className=" ion-padding" style={{ boxShadow: "none", textAlign: "left", width: "100%", }}>

            {/* <IonItem button={true} className="ion-padding-vertical ion-no-border"> */}
            {/* <IonGrid> */}
            {/* <IonRow className="ion-padding-vertical"> */}
            {/* <IonCol size="3"> */}
            {/* <IonThumbnail> */}
            {/* <img src={tempImage} alt="Image of the theme" /> */}
            {/* </IonThumbnail> */}
            {/* </IonCol> */}
            {/* <IonCol className="ion-align-self-center ion-padding-horizontal" style={{flexDirection: "column"}}> */}
            <ColumnContainer style={{ alignItems: "left" }}>
                <div style={{ borderRadius: "50%", margin: "4px auto", width: "8em", height: "8em", backgroundColor: "var(--ion-color-"+ getColor(theme) + ")", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <IonIcon color="light" style={{ fontSize: "3em" }} className="" icon={icon} />

                </div>
                <IonCardTitle style={{textAlign:'center'}}>
                    <MediumParagraph>{name}</MediumParagraph>
                </IonCardTitle>
            </ColumnContainer>
            {/* <IonProgressBar style={{padding: "0.3em 0em", borderRadius: "1em" }} color="tertiary" value={0.5}></IonProgressBar> */}
            {/* </IonCol> */}
            {/* </IonRow> */}
            {/* <IonRow> */}

            {/* </IonRow> */}
            {/* </IonGrid> */}
            {/* </IonItem> */}
        </IonCard>
    )
}

export default Outil
