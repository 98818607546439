import { IonButton, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import './Tab2.css';
import Header from '../components/Headers/Header';
import { pin, searchOutline, walk, warning, wifi, wine } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import Theme from '../components/Themes/Theme';
import SectionTitle from '../components/Titles/SectionTitle';
import ModuleSlider from '../components/Modules/ModuleSlider';
import SkeletonSlider from '../components/Modules/SkeletonSlider';
import { FetchedModuleContext } from '../contexts/ModuleContext';
import { OutilsContext } from '../contexts/OutilsContext';

import solutionImage from '../components/Themes/images/solution.svg'
import emotionImage from '../components/Themes/images/emotion.svg'
import confidenceImage from '../components/Themes/images/confidence.svg'
import stressImage from '../components/Themes/images/stress.svg'
import affirmationImage from '../components/Themes/images/affirmation.svg'
import motivationImage from '../components/Themes/images/motivation.svg'
import obstaclesImage from '../components/Themes/images/obstacles.svg'
import convictionsImage from '../components/Themes/images/convictions.svg'
import besoinsImage from '../components/Themes/images/besoins.svg'
import objectifsImage from '../components/Themes/images/objectifs.svg'
import valeursImage from '../components/Themes/images/valeurs.svg'
import penseeImage from '../components/Themes/images/pensée.svg'
import controleImage from '../components/Themes/images/contrôle.svg'
import Outil from '../components/Outils/Outil';
import OutilModal from '../components/Outils/OutilModal';
import SearchModule from '../components/SearchModule/SearchModule';

const Tab2: React.FC = () => {

  const { modules, loading } = useContext(FetchedModuleContext);
  const [sensationsModal, setSensationsModal] = useState(false);

  const outilsData = useContext(OutilsContext)
  // console.log("Outils data", outilsData)

  const listModules = [
    {color:'warning', name:'Les convictions',thumbnail:convictionsImage, target:'/themes/convictions'},
    {color:'primary', name:'Les pièges de la pensée',thumbnail:penseeImage, target:'/themes/pensée'},
    {color:'warning', name:'Les valeurs',thumbnail:valeursImage, target:'/themes/valeurs'},
    {color:'primary', name:'Les objectifs',thumbnail:objectifsImage, target:'/themes/objectifs'},
    {color:'primary', name:'Les états de perte de contrôle',thumbnail:controleImage, target:'/themes/controle'},
    {color:'secondary', name:'Gestion des émotions',thumbnail:emotionImage, target:'/themes/emotions'},
    {color:'primary', name:'Résolution des problèmes',thumbnail:solutionImage, target:"/themes/problemes"},
    {color:'primary', name:'La motivation',thumbnail:motivationImage, target:'/themes/motivation'},
    {color:'secondary', name:'Gestion du stress',thumbnail:stressImage, target:'/themes/stress'},
    {color:'secondary', name:'Confiance en soi',thumbnail:confidenceImage, target:'/themes/confiance'},
    {color:'warning', name:'Affirmation de soi',thumbnail:affirmationImage, target:"/themes/affirmation"},
    {color:'primary', name:'Les obstacles au changement',thumbnail:obstaclesImage, target:'/themes/obstacles'},
    {color:'warning', name:'Les besoins',thumbnail:besoinsImage, target:'/themes/besoins'}]







    //.sort(() => Math.random() - Math.random())

  return (
    <IonPage>
      <Header name="Apprendre" icon={searchOutline} iconTarget="/searchmodule" />
      <IonContent fullscreen>
        <Header name="Parcourir" icon={searchOutline} collapsible={true} iconTarget="/searchmodule" />
        <div className="page-wrapper ion-padding-horizontal">
          <div className="page-wrapper-content ">



            <SectionTitle title="Sélection par thèmes" />
            <IonGrid>
              <IonRow style={{ gap: "0" }}>

              { listModules.map((element) => {
                return(
                <IonCol key={element.name} size="6"  >
                  <Theme color={element.color} name={element.name} thumbnail={element.thumbnail} target={element.target} />
                </IonCol>
              )
              })
            }

              </IonRow>

              {/* <IonRow className="ion-margin-vertical" style={{gap: "1em"}}> */}
              {/* </IonRow> */}

            </IonGrid>

            <SectionTitle title="Récemment ajoutés" />
            {loading ? <SkeletonSlider /> : <ModuleSlider modules={modules}></ModuleSlider>}






            {/* <IonCard>
              <IonItem href="#" className="ion-activated">
                <IonIcon icon={wifi} slot="start" />
                <IonLabel>Card Link Item 1 activated</IonLabel>
              </IonItem>

              <IonItem href="#">
                <IonIcon icon={wine} slot="start" />
                <IonLabel>Card Link Item 2</IonLabel>
              </IonItem>

              <IonItem className="ion-activated">
                <IonIcon icon={warning} slot="start" />
                <IonLabel>Card Button Item 1 activated</IonLabel>
              </IonItem>

              <IonItem>
                <IonIcon icon={walk} slot="start" />
                <IonLabel>Card Button Item 2</IonLabel>
              </IonItem>
            </IonCard> */}

            {outilsData.map(outilItem => {
              return (
                <IonModal isOpen={outilItem.modalState}
                  cssClass="objectif-modal"
                  onDidDismiss={() => outilItem.setModalState(false)}
                  swipeToClose={true}
                  mode="ios"
                  key={outilItem.target}>
                  <OutilModal target={outilItem.target} objectif={outilItem.objectif} name={outilItem.name} handler={outilItem.modalState} setHandler={outilItem.setModalState} />
                </IonModal>)

            })}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab2;
