import React, {createContext, useState} from "react"


export interface CalendarContextProps {
  day:{},
  setDay:(day: {}) => void;
}

export const CalendarContext = createContext( {} as CalendarContextProps);

const CalendarContextProvider = (props: { children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; })=>{

  const [day, setDay] = useState({});

  const providerValue: CalendarContextProps = {day, setDay}
  return (
      <CalendarContext.Provider value={providerValue}>
          {props.children}
      </CalendarContext.Provider>
  )
}

export default CalendarContextProvider;
