import { IonItem, IonFabButton, IonIcon, IonCard } from '@ionic/react'
import { listOutline } from 'ionicons/icons'
import React from 'react'
import { ColumnContainer, Heading5, LargeParagraph, MediumParagraph, RowContainer } from '../../theme/globalStyles'

export interface SettingsItemProps {
    icon?: string;
    name: string;
    label?: string;
    target?: string;
    setHandler?:  React.Dispatch<React.SetStateAction<boolean>>

}

const SettingsItem: React.FC<SettingsItemProps> = ({ icon, name, label, target, setHandler }) => {
    return (
        <IonItem  button={setHandler ? true : false} onClick={() => setHandler ? setHandler(true) : null}>
            <RowContainer>
                
            {icon && (
            
                <IonIcon style={{fontSize: "1.5rem"}} icon={icon} color="dark" />
            )
            }
            <ColumnContainer style={{ marginLeft: "16px" }}>

                {label ?
                    (<>
                        <LargeParagraph style={{ margin: "1em 0 0", color: "var(--ion-color-dark)" }}>{name}</LargeParagraph>
                        <MediumParagraph style={{ margin: "0 0 01em", color: "var(--ion-color-medium)" }}>{label}</MediumParagraph>
                    </>
                    ) : (<LargeParagraph >{name}</LargeParagraph>)
                }
            </ColumnContainer>
                </RowContainer>
        </IonItem>
    )
}

export default SettingsItem
