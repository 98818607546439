import { IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonContent, IonList, IonItem, IonIcon, IonFabButton, IonTitle, IonModal, IonListHeader, IonLabel } from '@ionic/react'
import { arrowBackCircleOutline, bug, colorPalette, disc, documentText, informationCircle, list, lockClosed, prismOutline, shareSocial, star } from 'ionicons/icons'
import React, { useState } from 'react'
import Introduction from '../../pages/Survey/Introduction'
import { ColumnContainer, Heading4, Heading5, MediumParagraph } from '../../theme/globalStyles'
import SectionTitle from '../Titles/SectionTitle'
import SettingsItem from './SettingsItem';

import "./Settings.css"
import Objectif from '../../pages/Objectif/Objectif'
import ObjectifSettingModal from '../../pages/Objectif/ObjectifSettingModal'
import PrismeModal from '../../pages/About/PrismeModal'

const Settings = () => {
    const [surveyModal, setSurveyModal] = useState(false);
    const [aboutModal, setAboutModal] = useState(false);
    const [goalModal, setGoalModal] = useState(false);
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar  >
                    <IonButtons slot="start">
                        <IonBackButton color="dark" text="" defaultHref="/" />
                    </IonButtons>
                    <IonTitle>
                        <Heading4 style={{ color: "var(--ion-color-primary)", textAlign: "left" }}>Paramètres</Heading4>

                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                {/* <Header name="Mon espace" icon={personCircle} collapsible={true} /> */}
                <div className="page-wrapper ion-padding-horizontal">
                    <div className="page-wrapper-content ">
                        {/* <SectionTitle title="Paramètres" /> */}
                        {/* <IonItem>Test</IonItem> */}
                        <IonList lines="full">
                            <IonListHeader>
                                <Heading5>Personnel</Heading5>
                            </IonListHeader>
                            <SettingsItem name="Mes besoins" label="Réévaluer mes besoins" icon={list}  setHandler={setSurveyModal} target="/survey" />
                            <SettingsItem name="Mon objectif" label="Modifier mon objectif" icon={disc}  setHandler={setGoalModal} target="/objectif" />
                            {/* <SettingsItem name="Mes qualités" label="Retrouvez vos qualités" icon={star} color="warning" /> */}
                            {/* <SettingsItem name="Signalez un bug" label="Vous rencontrez un " icon={bug} color="danger" /> */}


                        </IonList>
                        <IonList>
                            <IonListHeader>
                                <Heading5>Apparence</Heading5>
                            </IonListHeader>
                            {/* <SettingsItem name="Vous avez une remarque" color="danger" /> */}
                            <SettingsItem name="Theme" icon={colorPalette} label="Changer le thème de couleur" />

                        </IonList>
                        <IonList>
                            <IonListHeader>
                                <Heading5>Information</Heading5>
                            </IonListHeader>
                            <SettingsItem name="Partager" icon={shareSocial} label="Partager cette app à vos proches"  />
                            <SettingsItem name="Politique de confidentialité" icon={lockClosed} label="Lire notre politique de confidentialité"  />
                            <SettingsItem name="Conditions d'utilisation" icon={documentText} label="Lire nos conditions d'utilisation"  />
                            <SettingsItem name="Version de l'app" icon={informationCircle} label="beta 0.0"  />
                            <SettingsItem name="A propos de nous" label="Association Prisme" icon={prismOutline}  setHandler={setAboutModal} target="/survey" />


                        </IonList>

                    </div>
                </div>
                <IonModal isOpen={surveyModal}
                    cssClass="questions-modal"
                    onDidDismiss={() => setSurveyModal(false)}
                    swipeToClose={true}
                    mode="ios">
                    <Introduction setModalHandler={setSurveyModal} />
                </IonModal>
                <IonModal isOpen={goalModal}
                    cssClass="objectif-modal"
                    onDidDismiss={() => setGoalModal(false)}
                    swipeToClose={true}
                    mode="ios">
                    <ObjectifSettingModal setModalHandler={setGoalModal} />
                </IonModal>
                <IonModal isOpen={aboutModal}
                    cssClass="objectif-modal"
                    onDidDismiss={() => setAboutModal(false)}
                    swipeToClose={true}
                    mode="ios">
                    <PrismeModal setHandler={setAboutModal} />
                </IonModal>

            </IonContent>
        </IonPage>
    )
}

export default Settings
