import React, {createContext, useState} from "react"


export interface RecommandationsContextProps {
  cps: string[],
  surveyCompleted: boolean,
  setCps: (cps: string[]) => void,
  setSurveyCompleted: (surveyCompleted: boolean) => void
}

// Contexte qui comprendra les deux CPS identifiés
export const RecommandationsContext = createContext( {} as RecommandationsContextProps);

const RecommandationsContextProvider = (props: { children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; })=>{
  const [cps, setCps] = useState(["null","null"])
  const [surveyCompleted, setSurveyCompleted] = useState(false)


  const providerValue: RecommandationsContextProps = {cps, surveyCompleted, setSurveyCompleted, setCps}
  return (
      <RecommandationsContext.Provider value={providerValue}>
          {props.children}
      </RecommandationsContext.Provider>
  )
}

export default RecommandationsContextProvider;
