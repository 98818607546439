import { IonBackButton, IonButton, IonButtons, IonCard, IonContent, IonFabButton, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonPage, IonProgressBar, IonRadio, IonRadioGroup, IonSlide, IonSlides, IonTitle, IonToolbar, useIonLoading } from '@ionic/react'
import { arrowForwardOutline, closeOutline, personCircle } from 'ionicons/icons'
import React, { useRef, useState,useContext } from 'react'
import Button from '../../components/Buttons/Button'
import Header from '../../components/Headers/Header'
import ModuleList from '../../components/Modules/ModuleList'
import { ColumnContainer, Heading4, Heading5, MediumParagraph, MediumButton, RowContainer } from '../../theme/globalStyles'
import tempImage from "../../components/Themes/Emotion.png";
import "./Survey.css";
import SkeletonSlider from '../../components/Modules/SkeletonSlider'
import {RecommandationsContext} from '../../contexts/Recommandation';



const Survey = () => {
    // const [surveyModal, setSurveyModal] = useState(true);
    const [surveyModal, setSurveyModal] = useState(true);
    const [selected, setSelected] = useState<string>('');
    const [completed, setCompleted] = useState(false);
    // récuperation des thèmes identifiés
    const { cps, surveyCompleted, setSurveyCompleted, setCps} = useContext(RecommandationsContext);
    // console.log(setCps)

    // const [selectedModal, setSurveyModal] = useState(false);

    const [currentQuestion, setCurrentQuestion] = useState(0);

    const [dict, setDict] = useState<any>({"emotions":[],"confiance":[],"affirmation":[],"stress":[],"problemes":[],"convictions":[],"pensee":[],"valeurs":[],"objectifs":[],"controle":[]})


    const slideOpts = {
        initialSlide: 0,
        slidesPerView: 1,
        speed: 500,
    };

    // a ref variable to handle the current slider
    const slider = useRef<HTMLIonSlidesElement>(null);

    const handleSurvey = (e: any) => {
        // console.log("hnadle survey", currentQuestion + 1, e.detail.value)
        let currenttheme = questions[currentQuestion].theme
        let currentvalue = e.detail.value
        if(currenttheme == "stress" || currenttheme=='pensee' || currenttheme=='controle'){
          currentvalue = 5-currentvalue
        }
        else{
          currentvalue = Number(currentvalue)
        }
        dict[currenttheme].push(currentvalue)

        if(currentQuestion + 1 == 10) {
            setCompleted(true);
            // for (var key in dict) {
            //     dict[key] = dict[key][0] + dict[key][1]
            //   }
            // console.log(dict);

            var items = Object.keys(dict).map(function(key) {
                return [key, dict[key]];
              });
              // Sort the array based on the second element
              items.sort(function(first, second) {
                return second[1] - first[1];
              });
              // console.log(items);
              // console.log(items.slice(8, 10)); //two smaller
              // console.log(items[8][0],items[9][0])
              setCps([items[8][0],items[9][0]])
              setSurveyCompleted(true)

        }
        { currentQuestion < questions.length && slider.current!.slideTo(currentQuestion + 1) }
        // setTimeout(() => {

        //     { currentQuestion < questions.length && slider.current!.slideTo(currentQuestion + 1) }
        // }, 200)
    }


    // a function to handle the slider changes
    const handleSlideChange = async (event: any) => {
        let index: number = 0;
        await event.target.getActiveIndex().then((value: any) => (index = value));
        setCurrentQuestion(index)
    }
    const questions = [
        { theme: "emotions", question: "J’arrive à mettre des mots sur ce que je ressens. " },
        { theme: "confiance", question: "Avec mes qualités et mes défauts, je pense que je vaux aussi bien que les autres." },
        { theme: "affirmation", question: "Je me sens capable d’exprimer mes pensées, mes besoins et mes opinions aux autres." },
        { theme: "stress", question: "Quand je suis stressée, il m’arrive de perdre tous mes moyens." },
        { theme: "problemes", question: "Lorsque je suis confrontée à un problème dans mon quotidien, j’arrive à trouver des solutions pour le résoudre (par exemple, demander conseil à un proche, rechercher)." },
        { theme: "convictions", question: "Pendant un échange avec une autre personne, je suis capable de me remettre en question." },
        {theme:'pensee', question: "Vous avez une vision pessimiste du monde."},
        {theme:'valeurs', question: "Je sais identifier ce qui est nécessaire à mon bien-être."},
        {theme:'objectifs', question: "Lorsque je prends une décision, j’arrive à m’y tenir."},
        {theme:'controle', question: "Quand je suis en colère, il m’arrive d’être impulsif."}
    ]
    // console.log(Object.keys(questions));
    return (
        <IonPage>
            <IonHeader >
                <IonToolbar >
                    <IonButtons slot="end">
                        <IonBackButton color="dark" defaultHref="/" text="" icon={closeOutline} />
                    </IonButtons>
                    <IonTitle>
                        {/* <Heading4>

                            Question {currentQuestion + 1} / {questions.length}
                        </Heading4> */}
                <IonProgressBar style={{ padding: "0.3em 0em", borderRadius: "1em", }} color="primary" value={(currentQuestion + 1) / questions.length} />

                    </IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <div className="page-wrapper ">
                    <div className="page-wrapper-content ">
                        <div className="ion-margin-horizontal">

                            {/* <IonProgressBar style={{ padding: "0.3em 0em", borderRadius: "1em", }} color="primary" value={(currentQuestion + 1) / questions.length} /> */}
                        </div>

                        <IonSlides className="swiper-no-swiping" options={slideOpts} ref={slider} onIonSlideDidChange={(e) => handleSlideChange(e)}>
                            {questions.map((themeQuestions, index) => {
                                // const theme = themeQuestions.theme;
                                const { question, theme } = themeQuestions
                                return (
                                    <IonSlide className="survey-slide" key={index}>
                                        {/* {question} */}
                                        <IonCard mode="ios" className="ion-padding-vertical" style={{boxShadow: "none"}}>

                                            <IonRadioGroup onIonChange={(e) => handleSurvey(e)}>
                                                <IonList lines="none">

                                                    <IonListHeader className="ion-margin-top">
                                                        {/* <IonLabel className="ion-margin-vertical"> */}
                                                        <Heading4 style={{color: "var(--ion-color-primary)", margin: "auto" ,fontSize:'1.2rem'}}>
                                                            {currentQuestion+1}. {question}
                                                        </Heading4>

                                                        {/* </IonLabel> */}
                                                    </IonListHeader>

                                                    {/* <IonCard button={true}> */}
                                                    <div className="ion-padding-horizontal" style={{ display: 'flex', flexDirection: 'column', gap: "8px" }}>
                                                    <MediumParagraph style={{textAlign: "left"}}>Sélectionnez une option.</MediumParagraph>

                                                        <IonItem style={{ width: '100%' }}>
                                                            {/* <div> */}

                                                            <MediumButton>1. Pas du tout d’accord</MediumButton>
                                                            <IonRadio slot="start" value="1" />
                                                            {/* </div> */}
                                                        </IonItem>
                                                        {/* </IonCard> */}
                                                        {/* <IonButton fill="outline" expand="block"> */}

                                                        <IonItem style={{ width: '100%' }}>
                                                            <MediumButton>2. Pas trop d’accord</MediumButton>
                                                            <IonRadio slot="start" value="2" />
                                                        </IonItem>
                                                        {/* </IonButton> */}
                                                        {/* <IonButton fill="outline" expand="block"> */}
                                                        <IonItem style={{ width: '100%' }}>
                                                            <MediumButton>3. Neutre</MediumButton>
                                                            <IonRadio slot="start" value="0" />
                                                        </IonItem>

                                                        <IonItem style={{ width: '100%' }}>
                                                            <MediumButton>4. Plutôt d’accord</MediumButton>
                                                            <IonRadio slot="start" value="3" />
                                                        </IonItem>
                                                        {/* </IonButton> */}
                                                        {/* <IonButton fill="outline" expand="block"> */}

                                                        <IonItem style={{ width: '100%' }}>
                                                            <MediumButton>5. Tout à fait d’accord</MediumButton>
                                                            <IonRadio slot="start" value="4" />
                                                        </IonItem>
                                                    </div>
                                                    {/* </IonButton> */}

                                                </IonList>

                                            </IonRadioGroup>


                                                {completed && (<IonButton  routerLink={"/tabs/accueil"} className="ion-margin-top">
                                                <p style={{color:"white", paddingRight:'10px',fontSize: '1.25rem',lineHeight: '130%',whiteSpace: 'normal'}}>Terminer </p> <IonIcon icon={arrowForwardOutline} />
                                            </IonButton>)}
                                        </IonCard>
                                    </IonSlide>
                                )
                                // themeQuestions.questionList.map((question, index) => <IonCard key={index}>{question}</IonCard>)
                            })}
                        </IonSlides>
                    </div>
                </div>
            </IonContent>
        </IonPage>

    )
}

export default Survey
