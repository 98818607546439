import { IonCard, IonContent, IonHeader, IonImg, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonThumbnail, IonTitle, IonToolbar, IonSegment,IonSlide, IonSlides, IonSegmentButton, IonModal, IonCol } from '@ionic/react';
import { pencilOutline, personCircleOutline, settingsOutline } from 'ionicons/icons';
import React, { useContext,  useRef, useState } from 'react';
import Header from '../components/Headers/Header';
import ModuleList from '../components/Modules/ModuleList';
import ModuleSlider from '../components/Modules/ModuleSlider';
import Calendar from '../components/Suivi/Calendar';
import Stats from '../components/Suivi/Stats';
import SectionTitle from '../components/Titles/SectionTitle';
import { FetchedModuleContext } from '../contexts/ModuleContext';
import { Heading5, MediumParagraph } from '../theme/globalStyles';
import './Tab3.css';
import {ObjectiveContext} from '../contexts/ObjectiveContext';
import {FavoritesContext} from '../contexts/FavoritesContext';
import {ModulesCompletedContext} from '../contexts/ModulesCompletedContext';
import { OutilsContext } from '../contexts/OutilsContext';
import OutilModal from '../components/Outils/OutilModal';
import Outil from '../components/Outils/Outil';

import Avatar from "boring-avatars";


type Item = {
  src: string;
  text: string;
};
const items: Item[] = [{ src: 'http://placekitten.com/g/200/300', text: 'a picture of a cat' }];


const Tab3: React.FC = () => {
  const {modules, loading} = useContext(FetchedModuleContext);
  const {objective, setObjective} = useContext(ObjectiveContext);
  const {favoritesModules, setFavoritesModules, favoritesTools, setFavoritesTools} = useContext(FavoritesContext);
  const {modulesCompleted, setModulesCompleted} = useContext(ModulesCompletedContext)

  const slider = useRef<HTMLIonSlidesElement>(null);
  // Segment State
  const [selectedFavSegment, setselectedFavSegment] = useState("0");

  const outilsData = useContext(OutilsContext)

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    loop: false,
    pagination: {
      el: null
    },

  };
  const sliderOptions = {
      // initialSlide: 0,
      speed: 400,
      slidesPerView: 1.5,
      spaceBetween: 8,
      breakpoints: {
          // when window width is >= 320px
          320: {
              slidesPerView: 1.15,
              spaceBetween: 20
          },
          // when window width is >= 480px
          480: {
              slidesPerView: 2.2,
              spaceBetween: 26
          },
          // when window width is >= 640px
          640: {
              slidesPerView: 2.2,
              spaceBetween: 16
          }
      }
  }

  const handleSegmentChange = (e: any) => {
    setselectedFavSegment(e.detail.value);
    slider.current!.slideTo(e.detail.value);
  };
  // a function to handle the slider changes
  const handleSlideChange = async (event: any) => {
    let index: number = 0;
    await event.target.getActiveIndex().then((value: any) => (index = value));
    setselectedFavSegment('' + index)
  }

  return (
    <IonPage >
      <Header name="Suivi" icon={settingsOutline} iconTarget="/settings" />
      <IonContent fullscreen >
      {/* <Header name="Suivi"  collapsible={true} icon={personCircleOutline} iconTarget="/espaceperso"/> */}

      <div className="page-wrapper ion-padding-horizontal">

          <div className="page-wrapper-content ">

          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "1em" }}>
            <div style={{ borderRadius: "50%" }}>

              <Avatar
                size={120}
                name="Emmet Larribeau"
                variant="beam"
                colors={["#366D9C", "#E05926", "#2FA377", "#EFA948", "#EF4949"]}
              />
            </div>
            <Heading5>Emmet Larribeau</Heading5>
            <MediumParagraph style={{ color: "var(--ion-color-medium)" }} >emmett.larribeau@example.com</MediumParagraph>
          </div>
          {
          // <SectionTitle title="Mon activité cette semaine"/>
          //
          // <Calendar />
          //
          // <SectionTitle title="Statistiques"/>
          //   <Stats label="Modules complétés" badge={8}/>
          //   <Stats label="Suite de jours consécutifs" badge={8}/>
        }

          <SectionTitle title="Mon objectif"/>
          <IonCard button={true} color="tertiary" routerLink={"/objectif"} style={{borderRadius: "8px"}} >
            <IonLabel >
              <MediumParagraph className="ion-padding">

                {objective}
              </MediumParagraph>
            </IonLabel>
          </IonCard>
          <SectionTitle title="Mon historique"/>
          {modulesCompleted.length==0 ? "Vous n'avez complété aucun module pour l'instant" : <ModuleSlider modules={modulesCompleted} />}

          <SectionTitle title="Mes favoris" />
          {
          // <IonSegment value={selectedFavSegment} onIonChange={e => handleSegmentChange(e)}>
          //   <IonSegmentButton value="0">
          //     <IonLabel>Modules</IonLabel>
          //   </IonSegmentButton>
          //   <IonSegmentButton value="1">
          //     <IonLabel>Outils</IonLabel>
          //   </IonSegmentButton>
          // </IonSegment>
        }
        {
          // <IonSlides pager={true} options={slideOpts} onIonSlideDidChange={(e) => handleSlideChange(e)} ref={slider}>
          //   <IonSlide className="ion-padding">
          //       {favoritesModules.length==0 ? "Vous n'avez aucun module favori pour l'instant" : <ModuleSlider modules={favoritesModules} />}
          //   </IonSlide>
          //
          //   <IonSlide className="ion-padding">
          //     <IonSlides options={slideOpts} style={{padding: "0.5em 0"}}>
          //
          //     {favoritesTools.length<=0 ? "Vous n'avez aucun outil favori pour l'instant" : outilsData.map((outilItem) =>{
          //       if(favoritesTools.includes(outilItem.target)){
          //         return (
          //           <IonSlide className="" style={{ height: "auto" }}>
          //           <Outil  name={outilItem.name} theme={outilItem.theme} id="outilsemotions" setHandler={outilItem.setModalState} icon={outilItem.icon} />
          //           </IonSlide>
          //           )
          //       }
          //
          //     })
          //    }
          //    </IonSlides>
          //    </IonSlide>
          // </IonSlides>
        }

        <Heading5>Modules</Heading5>
        {favoritesModules.length==0 ? "Vous n'avez aucun module favori pour l'instant" : <ModuleSlider modules={favoritesModules} />}

        <Heading5>Outils</Heading5>
        <IonSlides pager={false} options={sliderOptions} onIonSlideDidChange={(e) => handleSlideChange(e)} ref={slider}>
        {favoritesTools.length<=0 ? "Vous n'avez aucun outil favori pour l'instant" : outilsData.map((outilItem,i) =>{
              if(favoritesTools.includes(outilItem.target)){
                return (
                  <IonSlide key={i} className="" style={{ height: "auto"}}>
                  <Outil  name={outilItem.name} theme={outilItem.theme} id="outilsemotions" setHandler={outilItem.setModalState} icon={outilItem.icon} />
                  </IonSlide>
                  )
              }

            })
           }
          </IonSlides>

      {/* <IonList>
      {items.map((image, i) => (
        <IonItem key={i}>
          <IonThumbnail slot="start">
            <IonImg src={image.src} />
          </IonThumbnail>
          <IonLabel>{image.text}</IonLabel>
        </IonItem>
      ))}
    </IonList> */}



      </div>
    </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab3;
