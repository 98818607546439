import { IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonContent, IonInput, IonItem, IonLabel, IonButton, IonChip } from '@ionic/react'
import { arrowBackOutline } from 'ionicons/icons'
import React, { useState, useContext } from 'react'
import { Heading4, Heading5 } from '../../theme/globalStyles'
import {ObjectiveContext} from '../../contexts/ObjectiveContext';
import { toast } from '../../components/Toasts/Toast';


const Objectif = () => {
    const [text, setText] = useState<string>('');
    const {objective, setObjective} = useContext(ObjectiveContext);

    const handleObjective = (e: any) => {
      console.log("handleObjective", text)
      setObjective(text)
      toast('Objectif enregistré')
    }

    const handleChange = (text: string) => {
      setText(text)
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar >
                    <IonButtons slot="start">
                        <IonBackButton color="dark" text="" defaultHref="/" icon={arrowBackOutline} />
                    </IonButtons>
                    <IonTitle>
                        <Heading4 style={{ color: "var(--ion-color-primary)", textAlign: "center" }}>Objectif personnel</Heading4>
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
            <div className="page-wrapper ion-padding-horizontal">
                    <div className="page-wrapper-content " style={{display:'flex', flexDirection:'column'}}>
                    <Heading5>Suggestions</Heading5>
                    <div>
                        <IonChip style={{margin:'5px',padding:'10px'}} outline={true} onClick={() => handleChange("Gagner en confiance de soi")}>
                            <IonLabel color="primary">Gagner en confiance de soi</IonLabel>
                        </IonChip>
                        <IonChip style={{padding:'10px'}} outline={true} onClick={() => handleChange("Apprendre à mieux communiquer")}>
                            <IonLabel color="primary">Apprendre à mieux communiquer</IonLabel>
                        </IonChip>
                        <IonChip style={{padding:'10px'}} outline={true} onClick={() => handleChange("Gérer mon stress")}>
                            <IonLabel color="primary">Gérer mon stress</IonLabel>
                        </IonChip>
                    </div>
                <Heading5>Quel est votre objectif ?</Heading5>
                <IonItem>
            <IonLabel position="floating">Objectif</IonLabel>
            <IonInput onIonChange={(e: any) => handleChange(e.detail.value)} value={text}></IonInput>
          </IonItem>
          <IonButton style={{alignSelf:'center',marginTop:'20px'}} onClick={(e) => handleObjective(e)} >Enregistrer</IonButton>

                </div>
                </div>
            </IonContent>
        </IonPage>
    )

}

export default Objectif
