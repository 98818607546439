import React, {createContext, useEffect, useState} from "react"

import tempImage from "../components/Themes/Emotion.png";
import { toast } from "../components/Toasts/Toast";

export interface ProblemResolutionContextProps {
}

export const ProblemResolutionContext = createContext( {} as ProblemResolutionContextProps);

const ProblemResolutionContextProvider = (props: { children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; })=>{





  const providerValue: ProblemResolutionContextProps = {}
  return (
      <ProblemResolutionContext.Provider value={providerValue}>
          {props.children}
      </ProblemResolutionContext.Provider>
  )
}

export default ProblemResolutionContextProvider;
