import { IonPage, IonContent, IonToolbar, IonBackButton, IonHeader, IonTitle, IonIcon, IonButton, IonButtons, IonCard, IonSlide, IonSlides, IonItem, IonInput, IonItemDivider, IonLabel, IonTextarea,IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonFabButton, IonGrid, IonRow, IonText, IonRadio, IonList, IonRadioGroup, IonAlert, IonSegment, IonSegmentButton} from '@ionic/react'
import { happyOutline, help, manOutline, heart, heartCircleOutline, helpCircleOutline, closeCircleOutline } from 'ionicons/icons'
import React, { useRef, useState,useContext, useEffect } from 'react'
import { toast } from '../../components/Toasts/Toast';
import Favorite from "../../components/Outils/Favorite"
import { Heading4, Heading5, LargeParagraph, MediumButton } from '../../theme/globalStyles';
import { ProblemResolutionContext } from '../../contexts/ProblemResolutionContext';
import { Gesture, GestureConfig, createGesture } from '@ionic/core';
import "./Qualities.css";

const ProblemResolution = () => {



  return(
    <IonPage>
      <IonContent fullscreen style={{ "--background": "var(--ion-color-light)" }}>
          <IonHeader style={{ "--background": "transparent" }}>
              <IonToolbar>
                  <IonButtons slot="start">
                      <IonBackButton color="dark" defaultHref="/" text="" />

                  </IonButtons>
                  <IonTitle>
                      <Heading5>Résolution de problèmes</Heading5>
                  </IonTitle>
                  <IonButtons slot="end">
                    <Favorite name='/problemResolution'/>
                  </IonButtons>
              </IonToolbar>

          </IonHeader>
          <div className="page-wrapper ion-padding-horizontal" style={{ backgroundColor: "var(--ion-color-light)" }}>
              <div className="page-wrapper-content " >






              </div>

            </div>
      </IonContent>
    </IonPage>
  )
}

export default ProblemResolution
