import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonPage, IonRow, IonText, IonTitle, IonToolbar, useIonLoading, IonSlides, IonSlide } from '@ionic/react';
import { chevronForwardOutline, personCircle, personCircleOutline, settingsOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
// import useModules from '../components/Api/GetModules';
import Header from '../components/Headers/Header';
import Module, { ModuleProps } from '../components/Modules/Module';
import ModuleSlider from '../components/Modules/ModuleSlider';
import SmallModule from '../components/Modules/SmallModule';
import SectionTitle from '../components/Titles/SectionTitle';
import { Heading4, HorizontalScroll, LargeParagraph, MediumParagraph, RowContainer } from '../theme/globalStyles';
import './Tab1.css';
// import Prismic from "@prismicio/client";
import SkeletonCard from '../components/Modules/SkelettonCard';
import SkeletonSlider from '../components/Modules/SkeletonSlider';
import { FetchedModuleContext } from '../contexts/ModuleContext';
import Onboarding from '../components/Onboarding/Onboarding';
import {RecommandationsContext} from '../contexts/Recommandation';
import Theme from '../components/Themes/Theme';

import solutionImage from '../components/Themes/images/solution.svg'
import emotionImage from '../components/Themes/images/emotion.svg'
import confidenceImage from '../components/Themes/images/confidence.svg'
import stressImage from '../components/Themes/images/stress.svg'
import affirmationImage from '../components/Themes/images/affirmation.svg'
import motivationImage from '../components/Themes/images/motivation.svg'
import obstaclesImage from '../components/Themes/images/obstacles.svg'
import convictionsImage from '../components/Themes/images/convictions.svg'
import besoinsImage from '../components/Themes/images/besoins.svg'
import objectifsImage from '../components/Themes/images/objectifs.svg'
import valeursImage from '../components/Themes/images/valeurs.svg'
import penseeImage from '../components/Themes/images/pensée.svg'
import controleImage from '../components/Themes/images/contrôle.svg'



const Tab1: React.FC = () => {

  // const [modules, setModules] = useState<ModuleProps[]>([{description: "Hello darkness", name: "Emotion", theme: "Emotion", id: "1234"}, {description: "Hello darkness", name: "Emotion", theme: "Emotion", id: "1234", isFavorite: true}, {description: "Hello darkness", name: "Emotion", theme: "Emotion", id: "1234"}])
  // const [loading, setLoading] = useState<boolean>(true)
  const { modules, loading } = useContext(FetchedModuleContext);
  const { cps, surveyCompleted } = useContext(RecommandationsContext);
  const emotionalSkills = ["emotions","stress","confiance","estime"]

  const cognitiveSkills = ["problemes","pensee","objectifs","controle","motivation","obstacles"]

  const socialSkills = ["affirmation","besoins","valeurs","convictions"]

  const [firstLaunch, setFirstLaunch] = useState(true)
  const [recommandation, setRecommandation] = useState(false)
  const [present, dismiss] = useIonLoading();

  const thematics = [
    {color:'secondary', name:'Gestion des émotions',thumbnail:emotionImage, target:'/themes/emotions', shortname:"emotions"},
    {color:'primary', name:'Résolution des problèmes',thumbnail:solutionImage, target:"/themes/problemes", shortname:"problemes"},
    {color:'warning', name:'Affirmation de soi',thumbnail:affirmationImage, target:"/themes/affirmation", shortname:"affirmation"},
    {color:'secondary', name:'Gestion du stress',thumbnail:stressImage, target:'/themes/stress', shortname:"stress"},
    {color:'secondary', name:'Confiance en soi',thumbnail:confidenceImage, target:'/themes/confiance', shortname:"confiance"},
    {color:'warning', name:'Les besoins',thumbnail:besoinsImage, target:'/themes/besoins', shortname:"besoins"},
    {color:'primary', name:'La motivation',thumbnail:motivationImage, target:'/themes/motivation', shortname:"motivation"},
    {color:'warning', name:'Les convicitions',thumbnail:convictionsImage, target:'/themes/motivation', shortname:"convictions"},
    {color:'primary', name:'Les pièges de la pensée',thumbnail:penseeImage, target:'/themes/pensée', shortname:"pensee"},
    {color:'warning', name:'Les valeurs',thumbnail:valeursImage, target:'/themes/valeurs', shortname:"valeurs"},
    {color:'primary', name:'Les objectifs',thumbnail:objectifsImage, target:'/themes/objectifs', shortname:"objectifs"},
    {color:'primary', name:'Les états de perte de contrôle',thumbnail:controleImage, target:'/themes/controle', shortname:"controle"},
    {color:'primary', name:'Les obstacles au changement',thumbnail:obstaclesImage, target:'/themes/obstacles', shortname:"obstacles"}]

    const sliderOptions = {
        // initialSlide: 0,
        speed: 400,
        slidesPerView: 1.1,
        spaceBetween: 8,
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1.15,
                spaceBetween: 20
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 2.2,
                spaceBetween: 26
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 2.2,
                spaceBetween: 16
            }
        }
    }

  const falseLoading = () => {
    present({message: "Nos experts analysent vos besoins...", duration: 3000})
  }


  if(firstLaunch){
  const page = document.querySelector<HTMLElement>('#main')
  if(page){
    page.style.filter = "blur(3px)"
    page.style.transition = "0.5s"
    falseLoading()
    //page.style.filter = "blur(0px)"
    setTimeout(() => {
      page.style.filter = "blur(0px)"
      setRecommandation(!recommandation)
    }, 3000);
  }
  setFirstLaunch(!firstLaunch)
}

  return (
    <IonPage >
      <Header name="Bonjour, Emmet" icon={personCircleOutline} iconTarget="/tabs/suivi" />
      <IonContent fullscreen >
        <Header name="Accueil" icon={personCircleOutline} collapsible={true} iconTarget="/espaceperso" />
        <div className="page-wrapper ion-padding-horizontal">

          <div className="page-wrapper-content ">

          <SectionTitle title="Bienvenue !" showMore={false} />

            {/* <div className="ion-margin-top">

              <Heading4 style={{ color: "var(--ion-color-primary)" }}>
                Bonjour, Anne-Sophie
              </Heading4>
            </div> */}
            <SectionTitle title="Modules recommandés" showMore={false} />
            <LargeParagraph  style={{ color: "var(--ion-color-medium)" }}>
              Vous ignorez par où commencer ? Nous vous proposons les modules suivants
            </LargeParagraph>

            {//loading ? <SkeletonSlider /> : <ModuleSlider cps={cps} surveyCompleted={surveyCompleted} modules={modules}></ModuleSlider>
          }
          {
          loading ? <SkeletonSlider /> :
            recommandation&&<IonSlides options={sliderOptions}>
              {thematics.filter(thematic =>cps.includes(thematic.shortname)).map((module, index) => {
                return(
                  <IonSlide key={index} style={{ height: "auto" }}>
                    <Theme  color={module.color}
                            name={module.name}
                            thumbnail={module.thumbnail}
                            target={module.target} />
                  </IonSlide>
                )
              })}
                </IonSlides>
              }
            {/*
            <SectionTitle title="Modules favoris" showMore={true} target="/espaceperso"/> */}
            {/* {loading ? <SkeletonSlider /> : <ModuleSlider modules={modules}></ModuleSlider>} */}

            {//<SectionTitle title="Gestion des émotions" showMore={true} target="/themes/emotions" />
            //<LargeParagraph  style={{ color: "var(--ion-color-medium)" }}>
              //Quelques modules sur la gestion des émotions
            //</LargeParagraph>
            //{loading ? <SkeletonSlider /> : <ModuleSlider cps={["emotions"]} surveyCompleted={surveyCompleted} modules={modules} ></ModuleSlider>}
          }

            <SectionTitle title="Compétences émotionnelles" showMore={false} target="/themes/emotions" />

              {//loading ? <SkeletonSlider /> : <ModuleSlider cps={["emotions","stress","confiance","estime"]} surveyCompleted={surveyCompleted} modules={modules} ></ModuleSlider>
            }

              {loading ? <SkeletonSlider /> :
                <IonSlides style={{padding: "0.5em 0"}} options={sliderOptions}>
                  {thematics.filter(thematic =>emotionalSkills.includes(thematic.shortname)).map((module, index) => {
                    return(
                      <IonSlide key={index} style={{ height: "auto" }}>
                        <Theme  color={module.color}
                                name={module.name}
                                thumbnail={module.thumbnail}
                                target={module.target} />
                      </IonSlide>
                    )
                  })}
                    </IonSlides>}

            <SectionTitle title="Compétences cognitives" showMore={false} target="/themes/emotions" />

            {//loading ? <SkeletonSlider /> : <ModuleSlider cps={["problemes","pensee","objectifs","controle","motivation","obstacles"]} surveyCompleted={surveyCompleted} modules={modules} ></ModuleSlider>
          }
            {loading ? <SkeletonSlider /> :
              <IonSlides style={{padding: "0.5em 0"}} options={sliderOptions}>
                {thematics.filter(thematic =>cognitiveSkills.includes(thematic.shortname)).map((module, index) => {
                  return(
                    <IonSlide key={index} style={{ height: "auto" }}>
                      <Theme  color={module.color}
                              name={module.name}
                              thumbnail={module.thumbnail}
                              target={module.target} />
                    </IonSlide>
                  )
                })}
                  </IonSlides>}

            <SectionTitle title="Compétences sociales" showMore={false} target="/themes/emotions" />

            {//loading ? <SkeletonSlider /> : <ModuleSlider cps={["affirmation","besoins","valeurs","convictions"]} surveyCompleted={surveyCompleted} modules={modules} ></ModuleSlider>
          }
          {loading ? <SkeletonSlider /> :
            <IonSlides style={{padding: "0.5em 0"}} options={sliderOptions}>
              {thematics.filter(thematic =>socialSkills.includes(thematic.shortname)).map((module, index) => {
                return(
                  <IonSlide key={index} style={{ height: "auto" }}>
                    <Theme  color={module.color}
                            name={module.name}
                            thumbnail={module.thumbnail}
                            target={module.target} />
                  </IonSlide>
                )
              })}
                </IonSlides>}


            {/* {modules && <ModuleSlider modules={modules}></ModuleSlider>} */}
            {/* <Module unique={true} name="Emotion" isFavorite={true} description="Description du module" /> */}

          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab1;
