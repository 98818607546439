import { IonBackButton, IonButtons, IonContent, IonPage, IonSearchbar, IonToolbar } from '@ionic/react';
import React, { useState, useContext, useEffect } from 'react'
import { FetchedModuleContext } from '../../contexts/ModuleContext';
import { Heading5, MediumParagraph } from '../../theme/globalStyles';
import { ModuleProps } from '../Modules/Module';
import ModuleList from '../Modules/ModuleList';

const SearchModule: React.FC = () => {
    const [searchText, setSearchText] = useState('');
    const { modules, loading } = useContext(FetchedModuleContext);
    const [ searchResults, setSearchResults ] = useState([ ...modules ]);
    const performSearch = async () => {

		const searchTerm = searchText.toLowerCase();
		let tempResults: ModuleProps[] = [];

		if (searchTerm !== "") {
			modules.forEach(module => {

				if (module.name.toLowerCase().includes(searchTerm)) {

					tempResults.push(module);
				}
			});
		} else {

			tempResults = [ ...modules ];
		}

		setSearchResults(tempResults);
	}

	useEffect(() => {

		performSearch();
	}, [ searchText ]);
    return (
        <IonPage   >
      <IonContent fullscreen style={{ "--background": "var(--ion-color-light)"}}>
        {/* <IonHeader style={{ "--background": "transparent"}}> */}
        <IonToolbar style={{ "--background": "var(--ion-color-light)"}}>
          <IonButtons slot="start">
            <IonBackButton  color="dark" defaultHref="/" text="" />
          </IonButtons>
        </IonToolbar>

        {/* </IonHeader> */}
        <div className="page-wrapper ion-padding" style={{ width: '100%' }}>

            <div className="page-wrapper-content" >
                <Heading5 style={{color: "var(--ion-color-dark"}}>Rechercher un module</Heading5>
                <IonSearchbar mode="ios" value={searchText} onIonChange={e => setSearchText(e.detail.value!)}></IonSearchbar>
                <ModuleList modules={searchResults} />
            </div>
        </div>

        </IonContent>
        </IonPage>
    )
}

export default SearchModule
