import React, {createContext, useState} from "react"


export interface ObjectiveContextProps {
  objective: string,
  setObjective:(objective: string)=> void
}

export const ObjectiveContext = createContext( {} as ObjectiveContextProps);

const ObjectiveContextProvider = (props: { children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; })=>{

  const [objective, setObjective] = useState<string>('Cliquer ici pour mettre à jour votre objectif');

  const providerValue: ObjectiveContextProps = {objective, setObjective}
  return (
      <ObjectiveContext.Provider value={providerValue}>
          {props.children}
      </ObjectiveContext.Provider>
  )
}

export default ObjectiveContextProvider;
