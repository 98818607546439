import React, {createContext, useEffect, useState} from "react"


export interface ModulesCompletedContextProps {
  modulesCompleted: [],
  setModulesCompleted: (modulesCompleted: []) => void,
}

export const ModulesCompletedContext = createContext( {} as ModulesCompletedContextProps);

const ModulesCompletedContextProvider = (props: { children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; })=>{

  const [modulesCompleted, setModulesCompleted] = useState<any>([])

  const providerValue: ModulesCompletedContextProps = {modulesCompleted, setModulesCompleted}
  return (
      <ModulesCompletedContext.Provider value={providerValue}>
          {props.children}
      </ModulesCompletedContext.Provider>
  )
}

export default ModulesCompletedContextProvider;
