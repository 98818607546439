import React, {createContext, useEffect, useState} from "react"
import { ModuleProps } from "../components/Modules/Module";
import Prismic from "@prismicio/client";

import tempImage from "../components/Themes/Emotion.png";
import { toast } from "../components/Toasts/Toast";



const defaultModules : ModuleProps[] = [{description: "Hello darkness", name: "Emotion", theme: "emotions",longTheme: "Gestion des émotions", id: "1234"}]
export const FetchedModuleContext = createContext({modules: defaultModules, loading: true});

const FetchedModuleContextProvider = (props: { children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }) =>{
    const [modules, setModules] = useState<ModuleProps[]>(defaultModules)
    const [loading, setLoading] = useState<boolean>(true)
    const apiEndpoint = "https://skillsup.cdn.prismic.io/api/v2";
    const Client = Prismic.client(apiEndpoint);
    const [doc, setDocData] = useState<any>(null);




    function estimateReadingTime (body: any ) {
      let numberOfWords = 0;
      const wordsPerMinutes = 170;
      body.forEach((element: any) => {
        numberOfWords += element.text?.trim().split(/\s+/).length || 0;

      });
      const time = Math.ceil(numberOfWords / wordsPerMinutes);
      // console.log(numberOfWords, time)

      return time

    }

    const getLongTheme = (small:string) =>{
      let longTheme = ""
      switch(small.toLowerCase()){
          case "emotions":
              longTheme = "Gestion des émotions"
              break
          case "confiance":
              longTheme = "Confiance en soi"
              break
          case "affirmation":
              longTheme = "Affirmation de soi"
              break
          case "problemes":
              longTheme = "Résolution de problèmes"
              break
          case "stress":
              longTheme = "Gestion du stress"
              break
          case "convictions":
              longTheme = "Les convictions"
              break
          case "pensée":
              longTheme = "Les pièges de la pensée"
              break
          case "objectifs":
              longTheme = "Les objectifs"
              break
          case "contrôle":
              longTheme = "Les états de perte de contrôle"
              break
          case "motivation":
              longTheme = "La motivation"
              break
          case "estime":
              longTheme = "L'estime de soi"
              break
          case "obstacles":
              longTheme = "Les obstacles au changement"
              break
          case "besoins":
              longTheme = "Les besoins"
              break
          default:
              longTheme = small


      }
      return longTheme
  }

    useEffect(() => {
      console.log("effect hook")
      try {
        // console.log("try")
        const fetchData = async () => {
          const response = await Client.query(
            Prismic.Predicates.at("document.type", "module")
          );
          if (response) {
            // console.log(response)
            // toast("Fetching the data")
            let cleanedData = response.results.map(module => {
              // estimateReadingTime(module.data.body_content)
              return {
                id: module.id,
                theme: module.data.theme.toLowerCase(),
                longTheme: getLongTheme(module.data.theme),
                description: module.data.description[0]?.text || "Pas de description",
                name: module.data.title[0].text,
                thumbnail: module.data.thumbnail?.url || tempImage,
                body: module.data.body_content,
                readingTime: estimateReadingTime(module.data.body_content),
                videos: module.data.videos
              }

            })

            setTimeout(() => {

              // Set the modules

              setModules(cleanedData)
              // Set the loading state
              setLoading(false)
            }, 200);

          }


        }
        fetchData();
      }
      catch (error) {
        console.log("Can't fetch the data so show something to the client",error);
      }
      // fetchData();
      // eslint-disable-next-line
    }, []);



    return (
        <FetchedModuleContext.Provider value={{modules, loading}}>
            {props.children}
        </FetchedModuleContext.Provider>
    )

}

export default FetchedModuleContextProvider;
