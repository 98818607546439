import React, { createContext, useState  } from "react"
import { happyOutline, helpOutline, listOutline, accessibilityOutline, speedometerOutline } from 'ionicons/icons';




export interface OutilsDataProps {
    theme: string;
    target: string;
    objectif: string;
    name: string;
    modalState: boolean;
    icon:any;
    setModalState: React.Dispatch<React.SetStateAction<boolean>>;
    type: string;
}



// export const OutilsContext = createContext([{ theme: "emotions", target: "/emotionsensations", objectif: "Identifier le lien entre les ressentis corporels et les émotions", name: "Emotions et Sensations"}])
export const OutilsContext = createContext([] as OutilsDataProps[])

const OutilsContextProvider: React.FC = (props) => {
    // Emotions et Sensations
    const [sensationsModal, setSensationsModal] = useState(false);
    const [introspectionModal,setIntrospectionModal] = useState(false)
    const [qualitiesModal,setQualitiesModal] = useState(false)
    const [dailyGoalsModal,setDailyGoalsModal] = useState(false)
    const [problemResolutionModal,setproblemResolutionModal] = useState(false)



    const defaultOutils:OutilsDataProps[] = ([
        { theme: "emotions", target: "/emotionsensations", objectif: "Identifier le lien entre les ressentis corporels et les émotions", name: "Emotions et Sensations", modalState: sensationsModal,icon:happyOutline, setModalState: setSensationsModal, type:"game" },
        { theme: "estime", target: "/introspection", objectif: "Amener l’utilisateur à prendre du temps pour se poser des questions d’introspection sur sa journée. ", name: "Moment d'introspection",icon:helpOutline, modalState: introspectionModal, setModalState: setIntrospectionModal, type:"tool" },
        { theme: "valeurs", target: "/qualities", objectif: "Lancer l'utilisateur sur une réflexion portant sur ses propres qualités.", name: "Mes qualités", modalState: qualitiesModal,icon:accessibilityOutline, setModalState: setQualitiesModal, type:"tool" },
        { theme: "besoins", target: "/dailyGoals", objectif: "Indiquer les objectifs de la journée en précisant si ce sont des besoins ou des envies", name: "Mes objectifs quotidiens", modalState: dailyGoalsModal,icon:listOutline, setModalState: setDailyGoalsModal, type:"tool" },
        { theme: "problemes", target: "/problemResolution", objectif: "Indiquer les objectifs de la journée en précisant si ce sont des besoins ou des envies", name: "Résolution de problèmes", modalState: problemResolutionModal,icon:speedometerOutline, setModalState: setproblemResolutionModal, type:"tool" }
    ]);



    // const outilsData = defaultOutils;
    return (
        <OutilsContext.Provider value={defaultOutils} >
            {props.children}
        </OutilsContext.Provider>
    )
}

export default OutilsContextProvider;
