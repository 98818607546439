import { IonButton } from '@ionic/react'
import React, { useEffect } from 'react'
import { SetStateProps } from '../../pages/About/PrismeModal'
import { ColumnContainer, Heading4, Heading5, LargeParagraph, MediumButton } from '../../theme/globalStyles'


export interface OutilModalProps {
    name: string;
    objectif: string;
    target: string;
    handler: boolean;
    setHandler: React.Dispatch<React.SetStateAction<boolean>>
}


const OutilModal: React.FC<OutilModalProps> = ({ name, objectif, target, setHandler, handler }) => {
    useEffect(() => {
        console.log("sensationsModal no effect")
        return () => setHandler(false)
    }, [handler])
    return (
        <div className="page-wrapper ion-padding-horizontal" style={{ width: '100%'}}>

            <div className="page-wrapper-content" >

            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{height: "5px", width: "80px", backgroundColor: "var(--ion-color-medium)", margin: "1rem 0", borderRadius: "5px"}}></div>
                </div>

                <ColumnContainer className="ion-margin-vertical" style={{ textAlign: "center", gap: '1em'}}>
                    <Heading4 style={{ color: "var(--ion-color-primary)" }}>
                        {name}
                    </Heading4>
                    <Heading5 style={{ color: "var(--ion-color-primary)" }}>
                        Objectifs
                    </Heading5>
                    <LargeParagraph style={{ color: "var(--ion-color-dark)" }}>
                        {objectif}
                    </LargeParagraph>
                    <IonButton onClick={() => setHandler(false)} routerLink={target} style={{ "--border-radius": "16px", "--padding-bottom": "16px", "--padding-top": "16px" }} className="ion-margin-top" size="large" expand="block" fill="solid" color="primary">
                        <MediumButton>

                            Commencer
                        </MediumButton>

                    </IonButton>



                </ColumnContainer>

            </div>
        </div>
    )
}

export default OutilModal
