import React, {createContext, useState} from "react"


export interface DailyGoalsContextProps {
  tasks: {}
  setTasks: (needs: {}) => void;
  completed:{}
  setCompleted:(completed: {}) => void
}

// Contexte qui comprendra les deux CPS identifiés
export const DailyGoalsContext = createContext( {} as DailyGoalsContextProps);

const DailyGoalsContextProvider = (props: { children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; })=>{

  const [tasks, setTasks] = useState<any>({needs:[], desires:[]})
  const [completed, setCompleted] = useState<any>({})

  const providerValue: DailyGoalsContextProps = {tasks, setTasks, completed, setCompleted}
  return (
      <DailyGoalsContext.Provider value={providerValue}>
          {props.children}
      </DailyGoalsContext.Provider>
  )
}

export default DailyGoalsContextProvider;
