import React, {createContext, useEffect, useState} from "react"
import Prismic from "@prismicio/client";

import tempImage from "../components/Themes/Emotion.png";
import { toast } from "../components/Toasts/Toast";

export interface IntrospectionContextProps {
  questions: [],
  setQuestions: (questions: []) => void,
  loading: boolean,
  setLoading: (loading:boolean)=>void,
  memo: {},
  setMemo: (memo:{}) => void
}

export const IntrospectionContext = createContext( {} as IntrospectionContextProps);

const IntrospectionContextProvider = (props: { children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; })=>{

  const [questions, setQuestions] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [memo, setMemo] = useState<any>({})

  const apiEndpoint = "https://skillsup.cdn.prismic.io/api/v2";
  const Client = Prismic.client(apiEndpoint);
  const [doc, setDocData] = useState<any>(null);

  useEffect(() => {
    try {
      // console.log("try")
      const fetchData = async () => {
        const response = await Client.query(
          Prismic.Predicates.at("document.type", "introspection")
        );
        if (response) {

          let cleanData = response.results[0].data["questions_moment_d_introspection"]

          let data = Array()

          cleanData.forEach((element: any)=>{
            data.push(element.text)
          })

          setTimeout(() => {
            setQuestions(data.sort(() => Math.random() - 0.5)) //mix the list
            // Set the loading state
            setLoading(false)
          }, 200);

        }



        }



      fetchData();
    }
    catch (error) {
      console.log("Can't fetch the data so show something to the client",error);
    }
    // fetchData();
    // eslint-disable-next-line
  }, []);


  const providerValue: IntrospectionContextProps = {questions, setQuestions, loading, setLoading, memo, setMemo}
  return (
      <IntrospectionContext.Provider value={providerValue}>
          {props.children}
      </IntrospectionContext.Provider>
  )
}

export default IntrospectionContextProvider;
