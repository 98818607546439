import React, {createContext, useEffect, useState} from "react"
import Prismic from "@prismicio/client";
import { toast } from "../components/Toasts/Toast";


export interface QualitiesContextProps {
  mySkills: {}
  setMySkills:(mySkills: {}) => void,
  qualities: any[]
  setQualities: (qualities: any[]) => void,
  loading: boolean,
  setLoading: (loading:boolean)=>void,
}

// Contexte qui comprendra les deux CPS identifiés
export const QualitiesContext = createContext( {} as QualitiesContextProps);

const QualitiesContextProvider = (props: { children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; })=>{

  const [mySkills, setMySkills] = useState({});
  const [loading, setLoading] = useState<boolean>(true)
  const [qualities, setQualities] = useState<any>([])

  const apiEndpoint = "https://skillsup.cdn.prismic.io/api/v2";
  const Client = Prismic.client(apiEndpoint);
  const [doc, setDocData] = useState<any>(null);

  useEffect(() => {
    try {
      // console.log("try")
      const fetchData = async () => {
        const response = await Client.query(
          Prismic.Predicates.at("document.type", "qualites")
        );
        if (response) {
          let cleanData = response.results[0].data["qualites"]
          let data = Array()

          cleanData.forEach((element: any)=>{
            data.push([element.qualite[0].text,element.synonyme[0].text])
          })

          setTimeout(() => {
            setQualities(data) //mix the list
            // Set the loading state
            setLoading(false)
          }, 200);

        }



        }



      fetchData();
    }
    catch (error) {
      console.log("Can't fetch the data so show something to the client",error);
    }
    // fetchData();
    // eslint-disable-next-line
  }, []);


  const providerValue: QualitiesContextProps = {mySkills, setMySkills, qualities, setQualities, loading, setLoading}
  return (
      <QualitiesContext.Provider value={providerValue}>
          {props.children}
      </QualitiesContext.Provider>
  )
}

export default QualitiesContextProvider;
