import { IonButton, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import Header from '../components/Headers/Header';
import { pin, searchOutline, walk, warning, wifi, wine,gameControllerSharp } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import Theme from '../components/Themes/Theme';
import SectionTitle from '../components/Titles/SectionTitle';
import ModuleSlider from '../components/Modules/ModuleSlider';
import SkeletonSlider from '../components/Modules/SkeletonSlider';
import { FetchedModuleContext } from '../contexts/ModuleContext';
import { OutilsContext } from '../contexts/OutilsContext';

import Outil from '../components/Outils/Outil';
import OutilModal from '../components/Outils/OutilModal';
import SearchModule from '../components/SearchModule/SearchModule';
import { Heading4, HorizontalScroll, LargeParagraph, MediumParagraph, RowContainer } from '../theme/globalStyles';

const TabOutils: React.FC = () => {

  const { modules, loading } = useContext(FetchedModuleContext);
  const [sensationsModal, setSensationsModal] = useState(false);

  const outilsData = useContext(OutilsContext)
  // console.log("Outils data", outilsData)

  return (
    <IonPage>
      <Header name="S'exercer" icon={searchOutline} iconTarget="/searchmodule" />
      <IonContent fullscreen>
        <Header name="Parcourir" icon={searchOutline} collapsible={true} iconTarget="/searchmodule" />
        <div className="page-wrapper ion-padding-horizontal">
          <div className="page-wrapper-content ">

            <Heading4  style={{display:'flex', justifyContent:'center'}}> Outils Pratiques</Heading4>

            <IonGrid>
              <IonRow style={{ gap: "0" }}>

            {outilsData.filter(outil => outil.type=='tool').map((outilItem) => {
              return (
                <IonCol size="6" key={outilItem.target}>
                <Outil  name={outilItem.name} theme={outilItem.theme} id="outilsemotions" setHandler={outilItem.setModalState} icon={outilItem.icon} />
                </IonCol>
            )})}
            </IonRow>
            </IonGrid>

            <Heading4  style={{display:'flex', justifyContent:'center'}}> Jeux </Heading4>
            <IonGrid>
              <IonRow style={{ gap: "0" }}>

            {outilsData.filter(outil => outil.type=='game').map((outilItem) => {
              return (
                <IonCol size="6" key={outilItem.target}>
                <Outil  name={outilItem.name} theme={outilItem.theme} id="outilsemotions" setHandler={outilItem.setModalState} icon={outilItem.icon} />
                </IonCol>
            )})}
            </IonRow>
            </IonGrid>


            {outilsData.map(outilItem => {
              return (
                <IonModal isOpen={outilItem.modalState}
                  cssClass="objectif-modal"
                  onDidDismiss={() => outilItem.setModalState(false)}
                  swipeToClose={true}
                  mode="ios"
                  key={outilItem.target}>
                  <OutilModal target={outilItem.target} objectif={outilItem.objectif} name={outilItem.name} handler={outilItem.modalState} setHandler={outilItem.setModalState} />
                </IonModal>)

            })}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default TabOutils;
