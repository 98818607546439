import { IonCard, IonCardHeader, IonCardTitle } from '@ionic/react'
import React from 'react'
import { Heading5 } from '../../theme/globalStyles'
import themeImage from "./Emotion.png"
import emotionImage from "./images/solution.svg"


interface ThemeProps {
    name: string;
    thumbnail?: string;
    target?: string;
    color?: string;
}

export function getColor(theme: string) {
    let color = ""
    switch (theme.toLowerCase()) {
        case "emotions":
            color = "secondary"
            break
        case "confiance":
            color = "secondary"
            break
        case "affirmation":
            color = "warning"
            break
        case "problemes":
            color = "primary"
            break
        case "stress":
            color = "secondary"
            break
        case "besoins":
            color = "warning"
            break
        case "convictions":
            color = "warning"
          break
        case "pensée":
            color = "primary"
          break
        case "valeurs":
            color = "warning"
          break
        case "contrôle":
            color = "primary"
          break
        case "obstacles":
            color = "primary"
          break
        case "objectifs":
            color = "primary"
          break
        case "estime":
            color = "secondary"
          break
        default:
            color = "light"


    }
    return color;
}




const Theme: React.FC<ThemeProps> = ({ name, thumbnail, target, color }) => {
    return (
        <IonCard color={color || "light"} mode="ios" button={true} routerLink={target} style={{ maxWidth: "max-content", boxShadow: "none", padding: "8px", border: "2px solid var(--ion-color-light)",margin:'0px' ,height:"100%"}}   >
            <div className="ion-padding" style={{display: "flex", justifyContent: "center"}}>

                <img style={{width: "clamp(100px, 50%, 200px)"}}  src={thumbnail || emotionImage} alt="Image of the theme" />
            </div>

            <IonCardHeader>
                <IonCardTitle className="ion-text-center">
                    <Heading5>{name}</Heading5>
                </IonCardTitle>
            </IonCardHeader>
        </IonCard>
    )
}

export default Theme;
