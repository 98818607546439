import { IonBackButton, IonBadge, IonButton, IonButtons, IonChip, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonPage, IonSkeletonText, IonToolbar, IonCard } from '@ionic/react'
import { arrowBackCircleOutline, heartOutline, searchOutline, heart, checkboxOutline, checkmarkCircleOutline } from 'ionicons/icons'
import React, { useContext, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Header from '../components/Headers/Header'
import SectionTitle from '../components/Titles/SectionTitle'
import { FetchedModuleContext } from '../contexts/ModuleContext'
import { FavoritesContext } from '../contexts/FavoritesContext'
import { ModulesCompletedContext } from '../contexts/ModulesCompletedContext'
import { RichText } from 'prismic-reactjs'
import ModuleSlider from '../components/Modules/ModuleSlider'
import './ViewModule.css';
import { getColor } from '../components/Themes/Theme'

import { Heading4, Heading2, Heading5, LargeParagraph,LargeButton } from '../theme/globalStyles';

import { toast } from '../components/Toasts/Toast';


import solutionImage from '../components/Themes/images/solution.svg'
import emotionImage from '../components/Themes/images/emotion.svg'
import confidenceImage from '../components/Themes/images/confidence.svg'
import stressImage from '../components/Themes/images/stress.svg'
import affirmationImage from '../components/Themes/images/affirmation.svg'
import motivationImage from '../components/Themes/images/motivation.svg'
import besoinsImage from '../components/Themes/images/besoins.svg'
import objectifsImage from '../components/Themes/images/objectifs.svg'


type RouteParams = {
  id: string;
}


const ViewModule: React.FC = () => {
  const { id } = useParams<RouteParams>();
  const { modules, loading } = useContext(FetchedModuleContext);
  const { favoritesModules, setFavoritesModules, favoritesTools, setFavoritesTools } = useContext<any>(FavoritesContext);
  const { modulesCompleted, setModulesCompleted } = useContext<any>(ModulesCompletedContext);

  // useEffect(()=>{

  // }, [loading])
  const [module] = modules.filter(module => module.id === id);
  //console.log(module)

  function getImage(theme: string) {
    let image = ""
    switch (theme.toLowerCase()) {
      case "emotions":
        image = emotionImage;
        break
      case "confiance":
        image = confidenceImage
        break
      case "affirmation":
        image = affirmationImage
        break
      case "problemes":
        image = solutionImage
        break
      case "stress":
        image = stressImage
        break
      case "convictions":
        image = stressImage
        break
      case "pensée":
        image = stressImage
        break
      case "valeurs":
        image = stressImage
        break
      case "contrôle":
        image = stressImage
        break
      case "motivation":
        image = motivationImage
        break
      case "estime":
        image = stressImage
        break
      case "obstacles":
        image = stressImage
        break
      case "besoins":
        image = besoinsImage
        break
      case "objectifs":
        image = objectifsImage
        break
      //   default:
      //       image = "light"


    }
    return image
  }


  // const {theme} = module;
  const themeImage = getImage(module.theme);
  const color = getColor(module.theme)

  const history = useHistory();

  const handleCheck = (e:any) => {
    if(favoritesModules.includes(e)){
      setFavoritesModules(favoritesModules.filter((el:any) => el!=e))
    }
    else{
      favoritesModules.push(e)
      setFavoritesModules([...favoritesModules])
      toast("Le module à bien été enregistré dans vos favoris")

    }
  }

  const handleCompleted = (e:any) => {
    if(!modulesCompleted.includes(e)){
      modulesCompleted.push(e)
      setModulesCompleted([...modulesCompleted])
      history.goBack()
    }
    else{
      history.goBack()
    }
    toast('Votre avancée a bien été prise en compte')
  }


  return (
    <IonPage   >
      <IonContent fullscreen style={{ "--background": "var(--ion-color-light)"}}>
        {/* <IonHeader style={{ "--background": "transparent"}}> */}
        <IonToolbar style={{ "--background": "var(--ion-color-light)"}}>
          <IonButtons slot="start">
            <IonBackButton  color="dark" defaultHref="/" text="" />
          </IonButtons>
        </IonToolbar>

        {/* </IonHeader> */}
        <div className="page-wrapper" style={{ backgroundColor: "var(--ion-color-light)"}}>
          <div className="page-wrapper-content " >



            {loading || !module ? (
              <div className="ion-padding-vertical" style={{ padding: "3em 0" }}>

                <IonSkeletonText className="ion-margin-bottom" style={{ width: '100%', height: '15em' }} />
                <IonSkeletonText className="ion-margin-bottom" animated style={{ width: '30%', height: '2em' }} />
                {/* <IonSkeletonText animated style={{ width: '60%' }} /> */}
                {/* <IonSkeletonText animated /> */}
                <div className="ion-margin-vertical ion-padding-vertical">

                  <IonSkeletonText className="ion-margin-vertical" animated style={{ width: '100%', height: '1em' }} />
                  <IonSkeletonText className="ion-margin-vertical" animated style={{ width: '100%', height: '1em' }} />
                  <IonSkeletonText className="ion-margin-vertical" animated style={{ width: '60%', height: '1em' }} />
                </div>
                <div className="ion-margin-vertical ion-padding-vertical">

                  <IonSkeletonText className="ion-margin-vertical" animated style={{ width: '100%', height: '1em' }} />
                  <IonSkeletonText className="ion-margin-vertical" animated style={{ width: '100%', height: '1em' }} />
                  <IonSkeletonText className="ion-margin-vertical" animated style={{ width: '60%', height: '1em' }} />
                </div>
              </div>
            ) :
              (
                <div style={{ backgroundColor: "var(--ion-color-" + color + ")" }}>
                  <div className="parallax-image" style={{  position: "sticky", top: "0", height: "40vh", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center", maxWidth: "300px", margin: "auto" }}>
                    <img

                      style={{ padding: "1em", margin: "auto" }}
                      src={themeImage}
                      alt={module.name}
                    />
                  </div>
                  <div className="ion-padding" style={{ position: "relative", backgroundColor: "var(--ion-color-light)", borderRadius: "32px 32px 0px 0px",}}>
                    {/* <IonFab style={{position: "sticky", top: "0"}} horizontal="end" slot="fixed"> */}

                    {/* </IonFab> */}
                    {/* <SectionTitle  title={module.name}  /> */}
                    <Heading2 style={{ marginTop: "2em", textAlign: "center", textTransform: "capitalize"}}>
                      {module.name}
                    </Heading2>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                      <IonBadge className="ion-margin-vertical" style={{ padding: "0.5em 1em " }} color={color}>
                        {module.longTheme}
                      </IonBadge>



                      <IonButton onClick={() => handleCheck(module)} style={{ fontSize: "1.5em", padding: "0px" }} fill="clear" color="dark"  >
                        {favoritesModules.includes(module) && <IonIcon icon={heart} />}
                        {!favoritesModules.includes(module) && <IonIcon icon={heartOutline} />}
                      </IonButton>

                    </div>

                    {modulesCompleted.includes(module) && <div style={{display:'flex', flexDirection:'row', justifyContent:'center', color: "var(--ion-color-tertiary)"}}>
                      <p>Module complété</p>
                      <IonIcon style={{ fontSize: "1.5em", padding: "0px" }} icon={checkmarkCircleOutline}/>
                    </div>}

                    <div className="module_content " style={{ marginTop: "5em" }}>
                      <RichText render={module.body} />
                    </div>

                    <div>
                    {module.videos && module.videos.map((element:any,i:any)=>{
                      return(
                        <IonCard key={i} className="module_media" >
                        <iframe src={element.url.url} title="YouTube video player" frameBorder="0"  allowFullScreen/>
                        </IonCard>
                      )
                    })}
                    </div>

                    {/* <section className="ion-padding-horizontal">

                    <SectionTitle title={"Dans le même thème"} h5={true} />
                    <ModuleSlider modules={modules} />
                    </section> */}
                    <div className='module_completed'>

                          <IonButton onClick={()=>handleCompleted(module)} style={{ "--border-radius": "16px", "--padding-bottom": "16px", "--padding-top": "16px", alignSelf:'center' }} className="ion-margin-top" size="large" expand="block" fill="solid" color="primary">
                                  <p style={{padding:'10px'}}>J'ai terminé </p>
                              <IonIcon icon={checkboxOutline} />
                          </IonButton>

                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>

        {/* </div> */}
      </IonContent>
    </IonPage>
  )
}

export default ViewModule
