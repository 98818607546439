import { IonButton } from '@ionic/react'
import React from 'react'
import ModalHandle from '../../components/Settings/ModalHandle'
import { ColumnContainer, Heading5, Heading4, MediumParagraph, MediumButton } from '../../theme/globalStyles'

import prismeLogo from './Logo.svg'


export interface SetStateProps{
    setHandler:  React.Dispatch<React.SetStateAction<boolean>>
}

const PrismeModal: React.FC<SetStateProps> = ({setHandler}) => {
    return (
        <div className="page-wrapper ion-padding-horizontal">

            <div className="page-wrapper-content ">
            <ModalHandle />



                <ColumnContainer className="ion-margin-vertical">
                    {/* <RowContainer> */}
                    <Heading5 style={{ color: "var(--ion-color-primary)" }}>Crée par l'Association Prisme</Heading5>


                    <ColumnContainer className="ion-margin-vertical" style={{gap: "1em", marginTop: "4em"}}>
                        {/* <Heading4 className="ion-padding-top" style={{ color: "var(--ion-color-dark)", margin: "auto" }}>Association Prisme</Heading4> */}
                        <img className="ion-margin-bottom" style={{ maxWidth: "150px", margin: "auto" }} src={prismeLogo} alt="" />
                        <MediumParagraph style={{ color: "var(--ion-color-dark)" }}>
                            L'objectif de cette application est de permettre un renforcement des compétences psychosociales.
                        </MediumParagraph>
                        <MediumParagraph style={{ color: "var(--ion-color-dark)", textAlign: "left"}}>
                            L’association PRISME, basée sur Lille, propose des accompagnements psychologiques innovants aux personnes en parcours d’insertion, sous un format individuel et collectif.
                        </MediumParagraph>
                        <IonButton onClick={() => setHandler(false)} style={{ "--border-radius": "16px", "--padding-bottom": "16px", "--padding-top": "16px" }} className="ion-margin-top" size="large" expand="block" fill="outline" color="primary">
                            <MediumButton>

                                Revenir
                            </MediumButton>

                        </IonButton>
                        {/* <Button onClick={() => setSurveyModal(true)} label="Commencer" fill="outline"/> */}
                    </ColumnContainer>
                </ColumnContainer>

            </div>
        </div>
    )
}

export default PrismeModal
