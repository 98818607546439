import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { compassOutline, homeOutline, personCircleOutline, trendingUpOutline } from 'ionicons/icons';
import Tab1 from './pages/Tab1';
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';
import Tab4 from './pages/Tab4';
import "./App.css"

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';


/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import "./theme/fonts.css"
import "./theme/main.css"
import FetchedModuleContextProvider from './contexts/ModuleContext';
import RecommandationsContextProvider from './contexts/Recommandation';

import { MediumParagraph } from './theme/globalStyles';
import ViewModule from './pages/ViewModule';
import Tabs from "./components/Tabs/Tabs";
import Settings from './components/Settings/Settings';
import Survey from './pages/Survey/Survey';
import ViewTheme from './pages/ViewTheme';
import Objectif from './pages/Objectif/Objectif';
import EmotionSensation from './pages/Outils/EmotionSensation';
import Introspection from './pages/Outils/Introspection';
import Qualities from './pages/Outils/Qualities';
import DailyGoals from './pages/Outils/DailyGoals';
import ProblemResolution from './pages/Outils/ProblemResolution';
import OutilsContextProvider from './contexts/OutilsContext';
import ObjectiveContextProvider from './contexts/ObjectiveContext';
import IntrospectionContextProvider from './contexts/IntrospectionContext';
import QualitiesContextProvider from './contexts/QualitiesContext';
import DailyGoalsContextProps from './contexts/DailyGoalsContext';
import ProblemResolutionContextProps from './contexts/ProblemResolutionContext';
import FavoritesContextProvider from './contexts/FavoritesContext';
import ModulesCompletedContextProvider from './contexts/ModulesCompletedContext';

import CalendarContextProvider from './contexts/CalendarContext';

import Onboarding from './components/Onboarding/Onboarding';
import Introduction from './pages/Survey/Introduction';
import SearchModule from './components/SearchModule/SearchModule';



const App: React.FC = () => (


  <IonApp>
    <IonReactRouter>
      <ModulesCompletedContextProvider>
      <FavoritesContextProvider>
      <CalendarContextProvider>
      <DailyGoalsContextProps>
      <IntrospectionContextProvider>
      <QualitiesContextProvider>
      <ObjectiveContextProvider>
      <ProblemResolutionContextProps>

        <OutilsContextProvider>

      <RecommandationsContextProvider>
        <FetchedModuleContextProvider>
      <IonRouterOutlet id="main">
          <Route path="/tabs"  >
            <Tabs />
          </Route>
          <Route path="/espaceperso">
          <Tab4 />
        </Route>
          <Route path="/settings" exact={true} >
            <Settings />
          </Route>
          <Route path="/introduction" exact={true} >
            <Introduction />
          </Route>
          <Route path="/onboarding" exact={true} >
            <Onboarding />
          </Route>
          <Route path="/survey" exact >
            <Survey />
          </Route>
          <Route path="/searchmodule" exact >
            <SearchModule />
          </Route>
          <Route path="/objectif" exact >
            <Objectif />
          </Route>
          <Route path="/emotionsensations" exact >
            <EmotionSensation />
          </Route>
          <Route path="/introspection" exact >
            <Introspection />
          </Route>
          <Route path="/qualities" exact >
            <Qualities />
          </Route>
          <Route path="/dailyGoals" exact >
            <DailyGoals />
          </Route>
          <Route path="/problemResolution" exact >
            <ProblemResolution />
          </Route>

          <Route exact path="/module/:id" >
            <ViewModule />
          </Route>
          <Route exact path="/themes/:theme" >
            <ViewTheme />
          </Route>
          <Redirect exact from="/" to="/onboarding" />

      </IonRouterOutlet>
        </FetchedModuleContextProvider>
        </RecommandationsContextProvider>
        </OutilsContextProvider>
        </ProblemResolutionContextProps>
        </ObjectiveContextProvider>
        </QualitiesContextProvider>
        </IntrospectionContextProvider>
        </DailyGoalsContextProps>
        </CalendarContextProvider>
        </FavoritesContextProvider>
        </ModulesCompletedContextProvider>
    </IonReactRouter>
  </IonApp>
);

export default App;
