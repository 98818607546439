import { IonPage, IonContent, IonToolbar, IonBackButton, IonHeader, IonTitle, IonIcon, IonButton, IonButtons, IonCard, IonSlide, IonSlides, IonItem, IonInput, IonItemDivider, IonLabel, IonTextarea } from '@ionic/react'
import { happyOutline, help, manOutline } from 'ionicons/icons'
import React, { useRef, useState,useContext } from 'react'
import { toast } from '../../components/Toasts/Toast';
import Favorite from "../../components/Outils/Favorite"
import { Heading4, Heading5, LargeParagraph } from '../../theme/globalStyles';
import { IntrospectionContext } from '../../contexts/IntrospectionContext'

import "./Introspection.css"


const Introspection = () => {

  const {questions, setQuestions, loading, setLoading, memo, setMemo} = useContext(IntrospectionContext)

  const update: any = {}

  const handleSlideChange = async (event: any) => {
      let index: number = 0;
      await event.target.getActiveIndex().then((value: any) => (index = value));
  }

  const handleChange = (text: string, q: string) => {
    update[q] = text
  }

  const handleSave = (e: any) => {
    setMemo(Object.assign({}, memo, update))
    console.log('memo',memo)
  }

    return (

      <IonPage>
        <IonContent fullscreen style={{ "--background": "var(--ion-color-light)" }}>
            <IonHeader style={{ "--background": "transparent" }}>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton color="dark" defaultHref="/" text="" />

                    </IonButtons>
                    <IonTitle>
                        <Heading5>Moment d'introspection</Heading5>
                    </IonTitle>
                    <IonButtons slot="end">
                      <Favorite name='/introspection'/>
                    </IonButtons>
                </IonToolbar>

            </IonHeader>
            <div className="page-wrapper ion-padding-horizontal" style={{ backgroundColor: "var(--ion-color-light)" }}>
                <div className="page-wrapper-content " >
                    <LargeParagraph>
                        Voici une question sur laquelle tu pourras  prendre le temps de réfléchir durant la journée.
                    </LargeParagraph>
                    <LargeParagraph>
                        Si la question ne te plaît pas, n'hésite pas à swiper pour la changer.
                    </LargeParagraph>
                    <div className="introspectionQuestion">

                        <IonSlides style={{ display: "flex", flexDirection: "column" }} onIonSlideDidChange={(e) => handleSlideChange(e)}>
                            {questions.map((question, index) => {
                              return(
                              <IonSlide key={index}>
                                <Heading4 style={{minHeight: "8em", color: "var(--ion-color-primary)", margin: "auto", textAlign: "center",display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    {question}
                                </Heading4>
                                <IonItem>
                                  <IonTextarea onIonChange={(e: any) => handleChange(e.detail.value,question)}  placeholder="Laisser une note sur cette question" value={memo[question]}>
                                  </IonTextarea>
                                </IonItem>
                                <IonButton onClick={(e) => handleSave(e)} >Enregistrer</IonButton>
                              </IonSlide>

                            )})}
                        </IonSlides>


                    </div>
                </div>
              </div>
        </IonContent>
      </IonPage>

    )


}

export default Introspection
