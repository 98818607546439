import { IonPage, IonContent, IonToolbar, IonBackButton, IonHeader, IonTitle, IonIcon, IonButton, IonButtons, IonCard, IonSlide, IonSlides, IonItem, IonInput, IonItemDivider, IonLabel, IonTextarea,IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonFabButton, IonGrid, IonRow, IonText, IonRadio, IonList, IonRadioGroup, IonAlert, IonCheckbox, IonItemSliding,IonItemOptions,IonItemOption, IonSegment, IonSegmentButton} from '@ionic/react'
import { help, add, close,trash } from 'ionicons/icons'
import React,{ useRef, useState,useContext, useEffect } from 'react'
import { toast } from '../../components/Toasts/Toast';
import { Heading3,Heading4, Heading5, LargeParagraph, MediumButton } from '../../theme/globalStyles';
import { DailyGoalsContext } from '../../contexts/DailyGoalsContext';

import "./DailyGoals.css";

const DailyGoals = () => {

  const date = new Date();
  const options: any = { weekday: 'long', month: 'long', day: 'numeric' }
  const currentDate = date.toLocaleDateString('fr-FR', options)
  const cDate:any = date.toLocaleDateString()
  const sevenDay = new Date(new Date().setDate(new Date().getDate()-7)).toLocaleDateString()
  const thirtyDay = new Date(new Date().setDate(new Date().getDate()-30)).toLocaleDateString()

  const [button, setButton] = useState<any>({needs:true,desires:true});

  const {tasks, setTasks, completed, setCompleted} = useContext<any>(DailyGoalsContext)
  const [text, setText] = useState(" ");

  const [report,setReport] = useState('day')
  const [number,setNumber] = useState<any>(0)

  const handleAddButton= (e: any) => {
    button[e] = !button[e]
    setButton({...button})
  }

  const handleDelete = (list: string, e :string) => {
    tasks[list] = tasks[list].filter((task:any) => task != e )
    setTasks({...tasks})
  }

  const handleAddTask = (e:any) => {
    tasks[e].push({task:text, creationDate:currentDate, checked: false, type:e})
    setTasks(tasks)
    setText(" ")
    button[e] = !button[e]
    setButton({...button})
  }

  const handleCheck = (list:any,e:any) => {
    tasks[list][tasks[list].indexOf(e)].checked = !tasks[list][tasks[list].indexOf(e)].checked
    setTasks({...tasks})
    if(completed[cDate]){
            if(completed[cDate][completed[cDate].indexOf(e)]){
              // completed[cDate] = completed[cDate].filter((task:any) => task != e)
              // setCompleted(completed)
              setTimeout(function() {
                  handleDelete(list,e)
                }, 1000);
            }
            else{
            completed[cDate].push(e)
            setCompleted({...completed})
            setNumber(completed[cDate].length)
            console.log(completed)
            setTimeout(function() {
                handleDelete(list,e)
              }, 1000);
          }
    }
    else{
      completed[cDate] = [e]
      setCompleted({...completed})
      setNumber(completed[cDate].length)
      setTimeout(function() {
          handleDelete(list,e)
        }, 1000);
    }
  }

  return(
    <IonPage>
      <IonContent fullscreen style={{ "--background": "var(--ion-color-light)" }}>
          <IonHeader style={{ "--background": "transparent" }}>
              <IonToolbar>
                  <IonButtons slot="start">
                      <IonBackButton color="dark" defaultHref="/" text="" />

                  </IonButtons>
                  <IonTitle>
                      <Heading5>Mes objectifs quotidien</Heading5>
                  </IonTitle>
                  <IonButtons slot="end">

                      <IonButton fill="clear" color="dark" >
                          <IonIcon icon={help} />
                      </IonButton>
                  </IonButtons>
              </IonToolbar>

          </IonHeader>
          <div className="page-wrapper ion-padding-horizontal" style={{ backgroundColor: "var(--ion-color-light)" }}>
              <div className="page-wrapper-content " >

              <Heading4 style={{marginTop: '20px'}}>
                Aujourd'hui
              </Heading4>
              <Heading5 style={{margin: '0',fontSize: 'small'}}>
                {currentDate}
              </Heading5>

              <div className="need" style={{position:'relative'}}>
              <Heading5>
              Mes besoins
              </Heading5>

              {tasks.needs.map((need: any) => {
                return(
                    <IonCard key={need.task} style={{margin:'10px'}}>
                    <IonItemSliding>
                      <IonItem>
                      <IonCheckbox checked={need.checked} onClick={() => handleCheck('needs',need)} color="success" slot="start"></IonCheckbox>
                        <IonLabel style={{marginLeft:'10px'}}>{need.task}</IonLabel>
                      </IonItem>
                      <IonItemOptions side="end">
                          <IonItemOption onClick={() => handleDelete('needs',need)} color="danger">
                            <IonIcon icon={trash} slot="icon-only"></IonIcon>
                          </IonItemOption>
                        </IonItemOptions>
                        </IonItemSliding>
                    </IonCard>

            )
          })}

                {!button.needs && <IonCard>
                  <IonItem lines="none">
                    <IonInput onIonChange={(e: any) => setText(e.detail.value)} placeholder="Entrer une tâche">
                    </IonInput>
                    <IonButton mode="ios" shape="round" slot='end' color="primary" expand="block" fill="solid" onClick={(e) => handleAddTask("needs")} >
                      <IonIcon color="light" slot='icon-only' icon={add}/>
                    </IonButton>
                  </IonItem>
                </IonCard>}



                <IonButton onClick={() => handleAddButton("needs")} expand="block" fill="solid" color="primary" style={{margin: '10px 0 10px 0'}}>

                      {button.needs && <MediumButton>Ajouter un besoin</MediumButton>}
                      {!button.needs && <><MediumButton>Annuler</MediumButton>
                        <IonIcon color="light" slot='start' icon={close}/> </>}

                </IonButton>
              </div>

              <div className="desires" style={{position:'relative'}}>
              <Heading5>
              Mes Envies
              </Heading5>

              {tasks.desires.map((desire:any) => {
                return(
                    <IonCard key={desire.task} style={{margin:'10px'}}>
                    <IonItemSliding>
                      <IonItem>
                      <IonCheckbox checked={desire.checked} onClick={() => handleCheck('desires',desire)} color="success" slot="start"></IonCheckbox>
                        <IonLabel style={{marginLeft:'10px'}}>{desire.task}</IonLabel>
                      </IonItem>
                      <IonItemOptions onClick={() => handleDelete('desires',desire)} side="end" style={{width:'auto'}}>
                          <IonItemOption color="danger">
                            <IonIcon icon={trash} slot="icon-only"></IonIcon>
                          </IonItemOption>
                        </IonItemOptions>
                        </IonItemSliding>
                    </IonCard>
            )
          })}

                {!button.desires && <IonCard>
                  <IonItem lines="none">
                    <IonInput onIonChange={(e: any) => setText(e.detail.value)} placeholder="Entrer une envie">
                    </IonInput>
                    <IonButton mode="ios" shape="round" slot='end' color="primary" expand="block" fill="solid" onClick={(e) => handleAddTask("desires")} >
                      <IonIcon color="light" slot='icon-only' icon={add}/>
                    </IonButton>
                  </IonItem>
                </IonCard>}



                <IonButton onClick={() => handleAddButton("desires")} expand="block" fill="solid" color="primary" style={{margin: '10px 0 10px 0'}}>

                      {button.desires && <MediumButton>Ajouter une envie</MediumButton>}
                      {!button.desires && <><MediumButton>Annuler</MediumButton>
                        <IonIcon color="light" slot='start' icon={close}/> </>}

                </IonButton>
              </div>

              <div>
              
              </div>

              <div>
              <IonCard class= 'IonCardStyle'>
                <IonCardHeader>
                  <IonCardTitle>Quelle est la différence entre une envie et un besoin</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    Sed semper ante at imperdiet bibendum. Nam viverra enim a eros commodo, et luctus ligula egestas. Morbi nec sollicitudin massa. Aliquam nec pulvinar sem, at eleifend leo. Cras augue orci, euismod ac turpis in, tempor efficitur massa. Donec lacinia rutrum arcu, id aliquet velit varius id. Nam aliquam libero eu ipsum condimentum facilisis.
                  </IonCardContent>
                </IonCard>
              </div>

            </div>
          </div>
      </IonContent>
    </IonPage>
  )
}

export default DailyGoals
