import { IonButton, useIonRouter } from '@ionic/react'
import React from 'react'
import ModalHandle from '../../components/Settings/ModalHandle'
import { ColumnContainer, Heading5, Heading4, MediumParagraph, MediumButton } from '../../theme/globalStyles'

export interface ISettingsModal{
    setModalHandler?: React.Dispatch<React.SetStateAction<boolean>>
}
const ObjectifSettingModal: React.FC<ISettingsModal> = ({setModalHandler}) => {
    const router = useIonRouter()
    const handleRedirect = () =>{
        console.log("trying to close goal modal")
        if (setModalHandler) setModalHandler(false);
        router.push("/objectif", "forward");
    }
    return (
        <div className="page-wrapper ion-padding-horizontal">

            <div className="page-wrapper-content ">

                <ModalHandle />

                <ColumnContainer className="ion-margin-vertical" style={{ textAlign: "center" }}>
                    {/* <RowContainer> */}


                    <ColumnContainer className="ion-margin-vertical" style={{gap: "1em", marginTop: "4em"}}>
                        <Heading4 className="ion-padding-top" style={{ color: "var(--ion-color-dark)", margin: "auto" }}>Mon Objectif</Heading4>
                        <MediumParagraph style={{ color: "var(--ion-color-medium)" }}>
                            Votre objectif personnel, qui évolue au fil du temps
                        </MediumParagraph>
                        <IonButton onClick={handleRedirect} style={{ "--border-radius": "16px", "--padding-bottom": "16px", "--padding-top": "16px" }} className="ion-margin-top" size="large" expand="block" fill="solid" color="primary">
                            <MediumButton>
                                Changer l'objectif
                            </MediumButton>

                        </IonButton>
                    </ColumnContainer>
                </ColumnContainer>

            </div>
        </div>
    )
}

export default ObjectifSettingModal
