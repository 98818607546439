import {
    IonBadge,
    IonCard,

    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonChip,
    IonFabButton,
    IonGrid,
    IonIcon,
    IonLabel,
    IonRow,
    IonText,
} from "@ionic/react";
import { heart, heartOutline, hourglassOutline, checkmarkCircleOutline } from "ionicons/icons";
import GlobalStyle, {
    ColumnContainer,
    Heading5,
    MediumParagraph,
    ModuleWrapper,
    RowContainer,
    SmallParagraph,
} from "../../theme/globalStyles";
// import "../../theme/main.css"
// import tempImage from "../Themes/Emotion.png";
// import styled from "styled-components";

import solutionImage from '../Themes/images/solution.svg'
import emotionImage from '../Themes/images/emotion.svg'
import confidenceImage from '../Themes/images/confidence.svg'
import stressImage from '../Themes/images/stress.svg'
import affirmationImage from '../Themes/images/affirmation.svg'
import motivationImage from '../Themes/images/motivation.svg'
import obstaclesImage from '../Themes/images/obstacles.svg'
import convictionsImage from '../Themes/images/convictions.svg'
import besoinsImage from '../Themes/images/besoins.svg'
import objectifsImage from '../Themes/images/objectifs.svg'
import valeursImage from '../Themes/images/valeurs.svg'
import penseeImage from '../Themes/images/pensée.svg'
import controleImage from '../Themes/images/contrôle.svg'
import { getColor } from "../Themes/Theme";
import React, { useContext, useEffect, useState } from 'react'

import { ModulesCompletedContext } from '../../contexts/ModulesCompletedContext'


export interface ModuleProps {
    name: string;
    theme: string;
    longTheme?: string;
    id: string;
    isFavorite?: boolean;
    thumbnail?: string;
    description?: string;
    body?: any;
    unique?: boolean;
    readingTime?: number;
    videos?: any
}

const Module: React.FC<ModuleProps> = ({
    name,
    isFavorite,
    thumbnail,
    description,
    unique,
    theme,
    longTheme,
    id,
    readingTime
}) => {

    const getLongTheme = (small: string) => {
        let longTheme = ""
        switch (small) {
            case "emotions":
                longTheme = "Gestion des émotions"
                break
            case "confiance":
                longTheme = "Confiance en soi"
                break
            case "affirmation":
                longTheme = "Affirmation de soi"
                break
            case "problemes":
                longTheme = "Résolution de problèmes"
                break
            case "stress":
                longTheme = "Gestion du stress"
                break
            case "convictions":
                longTheme = "Les convictions"
                break
            case "pensée":
                longTheme = "Les pièges de la pensée"
                break
            case "objectifs":
                longTheme = "Les objectifs"
                break
            case "contrôle":
                longTheme = "Les états de perte de contrôle"
                break
            case "motivation":
                longTheme = "La motivation"
                break
            case "estime":
                longTheme = "L'estime de soi"
                break
            case "obstacles":
                longTheme = "Les obstacles au changement"
                break
            case "besoins":
                longTheme = "Les besoins"
                break
            case "objectifs":
                longTheme = "Les objectifs"
                break
            default:
                longTheme = small


        }
        return longTheme
    }

    function getImage(theme: string) {
        let image = ""
        switch (theme.toLowerCase()) {
            case "emotions":
                image = emotionImage;
                break
            case "confiance":
                image = confidenceImage
                break
            case "affirmation":
                image = affirmationImage
                break
            case "problemes":
                image = solutionImage
                break
            case "stress":
                image = stressImage
                break
            case "motivation":
                image = motivationImage
                break
            case "besoins":
                image = besoinsImage
                break
            case "convictions":
              image = convictionsImage
              break
            case "pensée":
              image = penseeImage
              break
            case "valeurs":
              image = valeursImage
              break
            case "contrôle":
              image = controleImage
              break
            case "obstacles":
              image = obstaclesImage
              break
            case "objectifs":
              image = objectifsImage
              break
            //   default:
            //       image = "light"


        }
        return image
    }
    const themeImage = getImage(theme);
    const { modulesCompleted, setModulesCompleted } = useContext<any>(ModulesCompletedContext);
    return (
        // <ModuleWrapper unique={unique} className="ion-padding">
        <IonCard color="light" routerLink={`/module/${id}`} mode="ios" button={true} className="ion-padding" style={{ boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)", textAlign: "center", height: "100%",  display: "flex", borderTop: "4px solid var(--ion-color-"+ getColor(theme) + ")", backgroundColor:'rgb(var(--ion-color-'+ getColor(theme) + '-rgb),0.2)',padding:'0'}} >
            <GlobalStyle />
            <IonGrid>
                <IonRow className="ion-justify-content-end">
                    {/* <IonChip style={{ padding: "0.5em 1em " }} color="primary">
                        {getLongTheme(theme)}
                    </IonChip> */}

                    {/* <IonIcon style={{ fontSize: "1.5rem", textAlign: "end" }} color={isFavorite ? "danger" : "dark"} icon={isFavorite ? heart : heartOutline} /> */}
                </IonRow>
            </IonGrid>

            {modulesCompleted.filter((element:any) => element.id==id).length>0  && <div style={{color: "var(--ion-color-tertiary)"}}>
            <IonIcon style={{ fontSize: "2em", padding: "0px", position:'absolute',right:'10px', top:'10px' }} icon={checkmarkCircleOutline}/>
            </div>}
            <div style={{ borderRadius: "50%",margin: "4px auto", width: "8em", height: "8em",  }}>
                {/* <IonFabButton color={getColor(theme)}  style={{ margin: "auto", width: "8em", height: "8em"}}> */}

                    <img

                        style={{padding: "1em", margin: "auto"}}
                        src={themeImage}
                        alt={name}
                    />
                {/* </IonFabButton> */}
            </div>
            <IonCardHeader>

                {/* <RowContainer> */}
                <RowContainer style={{ width: "max-content", margin: "8px auto 0px"}}>

                    <IonIcon icon={hourglassOutline} color="tertiary" />
                    <SmallParagraph style={{ color: "var(--ion-color-tertiary)", paddingLeft: "4px" }}>
                        {readingTime} minutes
                    </SmallParagraph>
                </RowContainer>
                {/* </RowContainer> */}
            </IonCardHeader>
            {/* <RowContainer style={{ justifyContent: "space-between" }}> */}
            <IonCardTitle>

                <Heading5>{name}</Heading5>
            </IonCardTitle>
            {/* </RowContainer> */}
            {/* <MediumParagraph style={{ color: "var(--ion-color-medium)" }}>{description}</MediumParagraph> */}
        </IonCard>
        // </ModuleWrapper>
    );
};

export default Module;
