import { IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonRouter } from '@ionic/react'
import React, { useState } from 'react'
import { ColumnContainer, Heading5, Heading4, MediumParagraph, MediumButton } from '../../theme/globalStyles'
import tempImage from "../../components/Themes/Emotion.png";
// import console from 'console';
import Lottie from "react-lottie";

import personnageAnimation from "./character.json"
import { ISettingsModal } from '../Objectif/ObjectifSettingModal';


const characterOptions = { loop: true, animationData: personnageAnimation, autoplay: true }


const Introduction: React.FC<ISettingsModal> = ({setModalHandler}) => {
    const router = useIonRouter()
    const handleRedirect = () =>{
        if (setModalHandler) setModalHandler(false);
        router.push("/survey", "forward");
    }
    return (
        <IonPage>

            <IonHeader>

                <IonToolbar >
                <IonTitle>
                        <Heading4 style={{ color: "var(--ion-color-primary)" }}>Création du profil</Heading4>
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                <div className="page-wrapper ion-padding-horizontal">

                    <div className="page-wrapper-content ">

                        {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{height: "5px", width: "80px", backgroundColor: "var(--ion-color-medium)", margin: "1rem 0", borderRadius: "5px"}}></div>
                </div> */}

                        <ColumnContainer className="ion-margin-vertical" style={{ textAlign: "center" }}>
                            {/* <RowContainer> */}


                            <ColumnContainer className="ion-margin-vertical" style={{ gap: "1em"}}>
                                <Heading4 className="ion-padding-top" style={{ color: "var(--ion-color-primary)", margin: "auto" }}>Votre profil</Heading4>
                                {/* <div style={{pointerEvents: "none"}}> */}
                                <Lottie isClickToPauseDisabled={true} options={characterOptions} height={230} width={300} />

                                {/* </div> */}
                                <MediumParagraph style={{ color: "var(--ion-color-dark)" }}>
                                    Vous êtes presque prêt à commencer, nous allons d'abord apprendre à vous connaître
                                    un peu mieux grâce au questionnaire qui va suivre.
                                </MediumParagraph>
                                <MediumParagraph style={{ color: "var(--ion-color-dark)" }}>
                                    Cela nous aidera à personnaliser
                                    votre expérience.
                                </MediumParagraph>
                                {/* <img className="ion-margin-bottom" style={{ maxWidth: "350px", margin: "auto" }} src={tempImage} alt="" /> */}
                                <IonButton onClick={handleRedirect} style={{ "--border-radius": "16px", "--padding-bottom": "16px", "--padding-top": "16px" }} className="ion-margin-top" size="large" expand="block" fill="solid" color="primary">
                                    <MediumButton>

                                        Commencer
                                    </MediumButton>

                                </IonButton>
                                {/* <Button onClick={() => setSurveyModal(true)} label="Commencer" fill="outline"/> */}
                            </ColumnContainer>
                        </ColumnContainer>

                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default Introduction
