import {
    IonCard,

    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonIcon,
    IonSkeletonText,
    IonText,
} from "@ionic/react";
import { heart, heartOutline, timeOutline } from "ionicons/icons";
import React from "react";
import GlobalStyle, {
    ColumnContainer,
    Heading5,
    MediumParagraph,
    ModuleWrapper,
    RowContainer,
    SmallParagraph,
} from "../../theme/globalStyles";
// import "../../theme/main.css"
import tempImage from "../Themes/Emotion.png";
// import styled from "styled-components";



const SkeletonCard: React.FC = () => {
    return (
        <IonCard mode="ios" button={true} className="ion-padding" style={{ boxShadow: "none", textAlign: "left", width: "100%", border: "1px solid" }} >
            <GlobalStyle />
            {/* <img
                className="ion-padding-top"
                src={tempImage}
                alt="Image of the theme"
            /> */}
            <IonSkeletonText style={{ height: "170px", width: "100%" }} />
            <IonCardHeader>

                <IonCardSubtitle style={{margin: "auto"}}>
                    {/* {/* <IonIcon icon={timeOutline} color="tertiary" /> */}
                    <SmallParagraph  style={{ color: "var(--ion-color-tertiary)", width: "max-content", margin: "1em auto" }}>
                        <IonSkeletonText style={{ width: "70px" }} animated={true} />

                    </SmallParagraph>
                </IonCardSubtitle>
            </IonCardHeader>
            <IonCardTitle>

                <Heading5>
                    <IonSkeletonText style={{ height: "15px" }} animated={true} />
                </Heading5>
            </IonCardTitle>
        </IonCard>
    );
};

export default SkeletonCard;
