import { IonPage, IonContent, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar, IonBackButton } from '@ionic/react';
import { arrowBackOutline, searchOutline } from 'ionicons/icons';
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom';
import Header from '../components/Headers/Header';
import ModuleList from '../components/Modules/ModuleList';
import SectionTitle from '../components/Titles/SectionTitle';
import { FetchedModuleContext } from '../contexts/ModuleContext';
import { Heading3, Heading5 } from '../theme/globalStyles';

type ThemeRouteParams = {
    theme: "emotions" | "confiance" | "affirmation" | "problemes" | "stress";
}



const ViewTheme: React.FC = () => {
    // Center yjis function somewhere for perf
    const getLongTheme = (small:string) =>{
        let longTheme = ""
        switch(small.toLowerCase()){
            case "emotions":
                longTheme = "Gestion des émotions"
                break
            case "confiance":
                longTheme = "Confiance en soi"
                break
            case "affirmation":
                longTheme = "Affirmation de soi"
                break
            case "problemes":
                longTheme = "Résolution de problèmes"
                break
            case "stress":
                longTheme = "Gestion du stress"
                break
            case "convictions":
                longTheme = "Les convictions"
                break
            case "pensée":
                longTheme = "Les pièges de la pensée"
                break
            case "objectifs":
                longTheme = "Les objectifs"
                break
            case "contrôle":
                longTheme = "Les états de perte de contrôle"
                break
            case "motivation":
                longTheme = "La motivation"
                break
            case "estime":
                longTheme = "L'estime de soi"
                break
            case "obstacles":
                longTheme = "Les obstacles au changement"
                break
            case "besoins":
                longTheme = "Les besoins"
                break
            default:
                longTheme = small


        }
        return longTheme
    }


    const { theme } = useParams<ThemeRouteParams>();
    const longTheme = getLongTheme(theme)
    const { modules, loading } = useContext(FetchedModuleContext);
    return (
        <IonPage>
            <IonHeader className="ion-padding-horizontal ">
                <IonToolbar   >
                    <IonButtons slot="start">
                        <IonBackButton color="dark" defaultHref="/" text="" icon={arrowBackOutline} />
                    </IonButtons>
                    <IonTitle >
                        <Heading5 style={{ color: "var(--ion-color-primary)", textAlign: "center" }}>{longTheme}</Heading5>
                    </IonTitle>
                </IonToolbar>

            </IonHeader>
            <IonContent fullscreen>
                {/* <Header name="theme" icon={searchOutline} collapsible={true} /> */}
                <div className="page-wrapper ion-padding-horizontal">

                    <div className="page-wrapper-content ">
                        {/* <SectionTitle title="Outils Pratiques" /> */}
                        <SectionTitle title="Modules" />

                        {
                            !loading && <ModuleList modules={modules} filteredTheme={theme} />}


                    </div>
                </div>
            </IonContent>
        </IonPage>

    )
}

export default ViewTheme
